import { Component, OnInit, Input } from '@angular/core';
import { PostService } from '../post.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { getThumb, zoomImage } from '../../../environments/util';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-posts-view',
  templateUrl: './posts-view.component.html',
  styleUrls: ['./posts-view.component.sass']
})
export class PostsViewComponent implements OnInit {
  @Input() post;
  files;
  getThumb = getThumb
  zoomImage = zoomImage

  constructor(
    private postService: PostService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (!this.post)
      this.postService.where([['slug', this.route.snapshot.paramMap.get('postSlug')]])
        .subscribe(resp => {
          console.log(resp)
          this.post = resp[0];
          // this.attachmentService.where([['targetId', this.post.id], ['targetDomain', 'posts']])
          //   .subscribe(files => {this.files = files});
        });
  }

}
