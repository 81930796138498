import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { apiUrl, formData, getToken, destroyToken } from '../../environments/util';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

export class ModelService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': '' })
  };
  htmlHttpOptions = {
    headers: new HttpHeaders({
      'Accept': 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'text/html'//'application/x-www-form-urlencoded'
    }),
    responseType: 'html'
  };
	domain;

  constructor(
		protected http: HttpClient, 
		protected router: Router
	) {}

  list(targetDomain:any = null, targetId:any = null, params: any = {}, reject = null): Observable<any> {
    if (targetDomain && targetId)
      return this.where([['targetId', targetId], ['targetDomain', targetDomain]]);

    let domain = !params.domain ? this.domain : params.domain;
    return this.http.get<any>(apiUrl + domain +'?apiToken='+ getToken()).pipe(
      retry(5),
			// tap(r => console.log('fetched '+ domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: list '+ domain, reject))
    );
  }

  where(conds: any = null, params: any = {}, reject = null): Observable<any> {
    let domain = !params.hasOwnProperty('domain') ? this.domain : params.domain, params_: any = [];
		
		params_.push({key: 'conds', value: conds ? JSON.stringify(conds) : ''})
		params_.push({key: 'apiToken', value: getToken()})
		
		if (params.hasOwnProperty('orderBy'))
			params_.push({key: 'orderBy', value: params.orderBy})
		if (params.hasOwnProperty('limit'))
			params_.push({key: 'limit', value: params.limit})
		if (params.hasOwnProperty('offset'))
			params_.push({key: 'offset', value: params.offset})
		if (params.hasOwnProperty('in'))
			params_.push({key: 'in', value: JSON.stringify(params.in)})
		if (params.hasOwnProperty('first'))
			params_.push({key: 'first', value: '1'})
		
		let params__ = params_.length > 0 ? params_.map(e => `${e.key}=${encodeURIComponent(e.value)}`).join('&') : ''
		
    return this.http.get<any>(apiUrl + domain + '/where'+ (params.hasOwnProperty('in') ? 'in' : '') + '?' + params__).pipe(
      retry(5),
			// tap(r => console.log('fetched '+ domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: where '+ domain, reject))
    );
  }

  find(id: number, reject = null): any {
    return this.http.get<any>(apiUrl + this.domain +'/'+ id +'?apiToken='+ getToken()).pipe(
      retry(5),
			// tap(r => console.log('fetched '+ this.domain)),
      tap(r => {/* console.log('response for '+id+' >>>>>>>', r) */}),
      catchError(this.handleError<any>('error on:: find '+ this.domain, reject))
    );
  }

  store(form: object, reject = null, params:any = {}): any {
    let domain = !params.domain ? this.domain : params.domain;
    let noauth = params.hasOwnProperty('noauth');
    let fdata = noauth ? formData(form) : formData(form);
    return this.http.post<any>(apiUrl + domain +'/store'+ (noauth ? '?noauth' : ''), fdata).pipe(
      retry(5),
			// tap(r => console.log('stored '+ domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: storing '+ domain, reject))
    );
  }

  save(id:number, form: any, reject = null, params:any = {}): any {
    let domain = !params.domain ? this.domain : params.domain;
		let noauth = params.hasOwnProperty('noauth');
    let fdata = noauth ? formData(form) : formData(form);
    return this.http.post<any>(apiUrl + domain +'/'+ id +'/save', fdata).pipe(
      retry(2),
			// tap(r => console.log('saved '+ domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: saving '+ domain, reject))
    );
  }

  destroy(id: number, params: any = {}, reject = null): any {
    let domain = !params.domain ? this.domain : params.domain;
    let noauth = params.hasOwnProperty('noauth');
    return this.http.delete<any>(apiUrl + domain +'/'+ id +'/destroy?apiToken='+ getToken()/* , {responseType: 'text'} */).pipe(
      retry(2),
			// tap(r => console.log('destroyed '+ domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: destroy '+ domain, reject))
    );
  }

  retreive(params: any = '', reject = null) {
    return this.http.get<any>(apiUrl + this.domain + '/retreive?apiToken='+ getToken() +'&'+ params).pipe(
      retry(5),
			// tap(r => console.log('retreived '+ this.domain)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: retreived '+ this.domain, reject))
    );
  }

  getTerms(domain: any) {
    domain = 'string' === typeof domain ? domain : domain.join(',');
    let suffix = 'string' === typeof domain ? '' : '-list';

    return this.http.get<any>(apiUrl +'/terms'+ suffix +'?terms='+ domain).pipe(
      retry(5),
			// tap(r => console.log(o)),
      tap(r => {}),
      catchError(this.handleError<any>('error on:: fetching terms for '+ domain))
    );
  }

  protected handleError<T> (operation = 'operation', reject = null, result?: T) {
    return (error: any): Observable<T> => {
      if (reject)
        reject(error.message);
			
			if ('UNKNOWN_TOKEN' === error.error) {
				destroyToken();
				this.router.navigate(['/signin']);
			}

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
