import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}
	
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
		// console.log('canActivate called');
    return this.checkLogin(url);
  }
	
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// console.log('canActivateChild called');
    return this.canActivate(next, state);
  }

  async checkLogin(url: string): Promise<boolean> {
		let status;
    await this.authService.check().then(() => status = true, r => {
			// this.authService.redirectUrl = url;
			this.router.navigate(['/signin', { redirect: url }]);
			status = false;
		});
		
		return status;
  }
  
}
