import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'definitions-list',
  templateUrl: './definitions-list.component.html',
  styleUrls: ['./definitions-list.component.sass']
})
export class DefinitionsListComponent implements OnInit {
  @Input() items;

  constructor() { }

  ngOnInit() {
  }

}
