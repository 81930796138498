import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.sass'],
  animations: speedDialFabAnimations
})
export class SpeedDialFabComponent implements OnInit {
  @Input() fabButtons;
  @Input() togglerState;
  @Output() downloaded = new EventEmitter<boolean>();
  @Output() printed = new EventEmitter<boolean>();
  buttons = [];

  constructor() {
    this.togglerState = !this.togglerState ? "inactive" : this.togglerState;
  }

  btnClick(btn: any): void {
    if ('file_download' === btn.icon)
      this.downloaded.emit(true);

    else if ('print' === btn.icon)
      this.printed.emit(true);

    else if (btn.hasOwnProperty('actionArgs'))
      btn.action(...btn.actionArgs);
    else
      btn.action();
  }

  showItems() {
    this.togglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.togglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  ngOnInit() {
    if ("active" === this.togglerState)
      this.showItems();
  }

}
