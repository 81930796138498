import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.sass']
})
export class EmptyComponent implements OnInit {
	@Input() link;
	@Input() message;
	@Input() icon;
	
  constructor() { }

  ngOnInit() {
  }

}
