import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-sample',
  templateUrl: './post-sample.component.html',
  styleUrls: ['./post-sample.component.sass']
})
export class PostSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
