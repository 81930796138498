import { Component, Input, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ElementRef, ChangeDetectorRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { RichTextEditor, ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { FormGroup, FormsModule }        from '@angular/forms';
import $ from 'jquery';
import { getThumb } from '../../../environments/util';

import { QuestionBase }     from './question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService]
})
export class DynamicFormQuestionComponent implements OnChanges {
	public tools: object = {/*
		items: ['Undo', 'Redo', '|',
			'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
			'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
			'SubScript', 'SuperScript', '|',
			'LowerCase', 'UpperCase', '|',
			'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
			'Indent', 'Outdent', '|', 'CreateLink',
			'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen'] */
		items: ['Undo', 'Redo', '|',
			'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
			'FontName', 'FontSize', '|',
			'SubScript', 'SuperScript', '|',
			'LowerCase', 'UpperCase', '|',
			'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
			'Indent', 'Outdent', '|', 'CreateLink',
			'|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
	};
	public quickTools: object = {
		image: [
			'Replace', 'Align', 'Caption', 'Remove', 'InsertLink', '-', 'Display', 'AltText', 'Dimension']
	};
  @ViewChild('fileContainer', {read: ElementRef, static: false}) fileContainer: ElementRef;
  @ViewChild('defaultRTE', {static: true}) defaultRTE: RichTextEditor;
  // @ViewChild('fileInput', {read: ElementRef, static: false}) fileInput: ElementRef;
	@ViewChild('template', {static: true}) template;
	// @ViewChild('valueTemplate', {static: true}) template;
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  @Output() fileChanged = new EventEmitter<any>();
  @Output() fieldChanged = new EventEmitter<any>();
  @Output() fieldKeyedUp = new EventEmitter<any>();
  @Output() removeFile = new EventEmitter<any>();
  @Input() files;
  selected: any;
  getThumb = getThumb;
  $ = $;
  filesBkgs: any = {};

	constructor(
    private viewContainerRef: ViewContainerRef,
    private ref: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes>>>", changes)
  }

	ngOnInit() {
		// if ('rte' === this.question.controlType && this.question.hasOwnProperty('value') && this.question.value !== '')
			// console.log('rte>>>>>>>>>', this.defaultRTE);
    this.selected = this.question['value'] ? (isNaN(this.question.value) ? this.question.value : parseInt(this.question.value)) : '';
    this.viewContainerRef.createEmbeddedView(this.template);
    this.ref.detectChanges();
	}

  ngAfterViewInit() {
    // AUTOCOMPLETE FIELDS
    // $('[data-options]').hide()
  }

  setBackground(file, i, key): string {
    if (!this.filesBkgs[key])
      this.filesBkgs[key] = {};

    if (!this.filesBkgs[key][i])
      this.filesBkgs[key][i] = 'string' === typeof file ? getThumb(file) : URL.createObjectURL(file);

    return 'url('+ this.filesBkgs[key][i] +')';
  }

  detectChanges() {
    this.ref.detectChanges();
  }

  get isValid() { return this.form.controls[this.question.key].valid; }

	updateCheckbox(rootKey, rootValue) {
		let values = !this.form.controls[rootKey].value ? [] : JSON.parse(this.form.controls[rootKey].value),
			key = [rootKey, rootValue].join('__');

		if (this.form.controls[key].value)
			values.splice(values.indexOf(rootValue), 1);
		else
			values.push(rootValue);

		this.form.controls[rootKey].setValue(values.length < 1 ? '' : JSON.stringify(values));

		// this.form.controls[rootKey].status = values.length > 0 ? 'VALID' : (this.form.controls[rootKey].errors && this.form.controls[rootKey].errors.hasOwnProperty('required') ? 'INVALID' : 'VALID');
	}

  stringify(e) {
    return JSON.stringify(e)
  }
}
