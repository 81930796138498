import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiUrl } from '../../environments/util';
import { ModelService } from '../@services/model.service';
import { DropdownQuestion } from '../@widgets/dynamic-form/question-dropdown';
import { QuestionBase } from '../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../@widgets/dynamic-form/question-textbox';
import { AutocompleteQuestion } from '../@widgets/dynamic-form/question-autocomplete';
import { CheckboxQuestion } from '../@widgets/dynamic-form/question-checkbox';
import { RadioQuestion } from '../@widgets/dynamic-form/question-radio';
import { Router, ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class SiteService extends ModelService {

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
    this.domain = 'sites';
  }

  getQuestions(site = null) {
    return new Promise((resolve, reject) => {
      let proceed = () => {
        let credentials: QuestionBase<any>[] = [
          new TextboxQuestion({
            key: 'credentialsSubheader',
            label: 'Mes identifiants',
            type: 'subheader',
            info: 'Ces identifiants vous permettront de vous connecter à votre espace administrateur'
          }),
          new TextboxQuestion({
            key: 'pseudo',
            label: 'Pseudonyme',
            required: true
          }),
          // new TextboxQuestion({
            // key: 'userEmail',
            // label: 'Email',
            // type: 'email',
            // required: true,
            // info: 'Veuillez entrer un email valide, vous aurez à le confirmer avant d\'utiliser votre compte'
          // }),
          new TextboxQuestion({
            key: 'password',
            label: 'Mot de passe',
            type: 'password',
            required: true
          })
        ];

        let basic: QuestionBase<any>[] = [
          new TextboxQuestion({
            key: 'basicSubheader',
            label: 'Informations basiques',
            type: 'subheader'
          }),
          new TextboxQuestion({
            key: 'name',
            label: 'Nom de l\'association',
            required: true,
            value: site ? site.name : ''
          }),
          new TextboxQuestion({
            key: 'slug',
            label: 'Nom du sous-domaine',
            info: 'Pas d\'espaces ou caracteres spéciaux. Si vide, il sera généré automatiquement en fonction du nom de l\'établissement.',
            disabled: !!site,
						value: site ? site.slug : ''
          }),
          new TextboxQuestion({
            key: 'motto',
            label: 'Mot d\'ordre',
            value: site ? site.motto : ''
          }),
          new TextboxQuestion({
            key: 'bio',
            label: 'Parlez-nous de votre association',
            required: true,
            type: 'textarea',
            info: 'Au moins 150 caractères',
            value: site ? site.bio : ''
          }),
          new TextboxQuestion({
            key: 'ceoSpeech',
            label: 'Le mot du Président',
            type: 'textarea',
            value: site ? site.ceoSpeech : ''
          }),
          new TextboxQuestion({
            key: 'locationSubheader',
            label: 'Adresse',
            type: 'subheader'
          }),
          new TextboxQuestion({
            key: 'city',
            label: 'Ville',
            required: true,
            col: true,
            value: site ? site.city : ''
          }),
          new TextboxQuestion({
            key: 'streetName',
            label: 'Rue / Quartier',
            required: true,
            col: true,
            value: site ? site.streetName : ''
          }),
          new TextboxQuestion({
            key: 'contactSubheader',
            label: 'Informations de contact',
            type: 'subheader'
          }),
          new TextboxQuestion({
            key: 'tel',
            label: 'Téléphone',
            required: true,
            type: 'number',
            col: true,
            value: site ? site.tel : ''
          }),
          new TextboxQuestion({
            key: 'whatsapp',
            label: 'WhatsApp',
            col: true,
            value: site ? site.whatsapp : ''
          }),
          new TextboxQuestion({
            key: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            value: site ? site.email : ''
          }),
          new TextboxQuestion({
            key: 'otherSubheader',
            label: 'Tontine et autres',
            type: 'subheader'
          }),
          new TextboxQuestion({
            key: 'defaultTontineNamePrice',
            label: 'Montant par défaut d\'un nom',
            type: 'number',
            value: site ? site.defaultTontineNamePrice : ''
          }),
          new TextboxQuestion({
            key: 'defaultTontineLatePenalty',
            label: 'Montant par défaut de la sanction d\'un retard',
            type: 'number',
            value: site ? site.defaultTontineLatePenalty : ''
          }),
          new TextboxQuestion({
            key: 'defaultTontineFailPenalty',
            label: 'Montant par défaut de la sanction d\'un échec',
            type: 'number',
            value: site ? site.defaultTontineFailPenalty : ''
          }),
          new TextboxQuestion({
            key: 'loanInterestRate',
            label: 'Taux d\'interêt d\'un prêt',
            type: 'number',
            value: site ? site.loanInterestRate : ''
          })
        ];
				
				resolve({credentials: credentials, basic: basic});
      }
      
			proceed();
      // this.http.get<any>(apiUrl +'/terms-list?terms=region').pipe()
        // .subscribe(_ => {
          // proceed(_);
        // });
    });
  }

  retreive(slug: string) {
    return this.http.get<any>(apiUrl + this.domain + '/retreive?slug=' + encodeURIComponent(slug)).pipe(
      // tap(_ => console.log('retreived '+ this.domain)),
      tap(_ => {}),
      catchError(this.handleError<any>('error on:: retreive '+ this.domain))
    );
  }
}
