import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.sass']
})
export class SelectItemsComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

  ngOnInit() {
  }

}
