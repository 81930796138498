import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiUrl, formData } from '../../environments/util';
import { ModelService } from '../@services/model.service';
import { DropdownQuestion } from '../@widgets/dynamic-form/question-dropdown';
import { QuestionBase } from '../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../@widgets/dynamic-form/question-textbox';
import { AutocompleteQuestion } from '../@widgets/dynamic-form/question-autocomplete';
import { CheckboxQuestion } from '../@widgets/dynamic-form/question-checkbox';
import { RadioQuestion } from '../@widgets/dynamic-form/question-radio';
import { Router, ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends ModelService {

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
    this.domain = 'attachments';
  }

  save(id:number, form: any, reject = function(_) {console.log(_)}, params:any = {}): any {
    let domain = 'attachments';
    let fdata = formData(form);
    return this.http.post<any>(apiUrl + domain +'/save', fdata).pipe(
      tap(_ => console.log('saved '+ domain)),
      catchError(this.handleError<any>('error on:: saving '+ domain, reject))
    );
  }

  update(id:number, form: any, params:any = {}): any {
    let domain = params['domain'] || this.domain;
    let fdata = formData(form);
    return this.http.post<any>(apiUrl + 'attachments/'+ id +'/update', fdata).pipe(
      tap(_ => console.log('saved '+ domain)),
      catchError(this.handleError<any>('error on:: saving '+ domain))
    );
  }
}
