import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SigninComponent } from './auth/signin/signin.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { ShowcaseHomeComponent } from './showcase/showcase-home/showcase-home.component';
import { ShowcaseAboutComponent } from './showcase/showcase-about/showcase-about.component';
import { ShowcaseAssociationsComponent } from './showcase/showcase-associations/showcase-associations.component';
import { ShowcaseChiefsComponent } from './showcase/showcase-chiefs/showcase-chiefs.component';
import { ShowcaseChiefTalkComponent } from './showcase/showcase-chief-talk/showcase-chief-talk.component';
import { ShowcaseContactComponent } from './showcase/showcase-contact/showcase-contact.component';
import { ShowcaseFestivitiesComponent } from './showcase/showcase-festivities/showcase-festivities.component';
import { ShowcaseGalleryComponent } from './showcase/showcase-gallery/showcase-gallery.component';
import { ShowcaseGalleryVideosComponent } from './showcase/showcase-gallery/showcase-gallery-videos/showcase-gallery-videos.component';
import { ShowcaseGalleryVideosViewComponent } from './showcase/showcase-gallery/showcase-gallery-videos/showcase-gallery-videos-view/showcase-gallery-videos-view.component';
import { ShowcaseGalleryPhotosComponent } from './showcase/showcase-gallery/showcase-gallery-photos/showcase-gallery-photos.component';
import { ShowcaseGalleryPhotosAlbumComponent } from './showcase/showcase-gallery/showcase-gallery-photos/showcase-gallery-photos-album/showcase-gallery-photos-album.component';
import { ShowcaseHistoryComponent } from './showcase/showcase-history/showcase-history.component';
import { ShowcaseFestivitiesF2017Component } from './showcase/showcase-festivities/showcase-festivities-f2017/showcase-festivities-f2017.component';
import { ShowcaseFestivitiesF2010Component } from './showcase/showcase-festivities/showcase-festivities-f2010/showcase-festivities-f2010.component';
import { ShowcaseFestivitiesF2007Component } from './showcase/showcase-festivities/showcase-festivities-f2007/showcase-festivities-f2007.component';
import { ShowcaseFestivitiesF2009Component } from './showcase/showcase-festivities/showcase-festivities-f2009/showcase-festivities-f2009.component';
import { SitesSettingsComponent } from './sites-settings/sites-settings.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
		SigninComponent,
		ShowcaseComponent,
		ShowcaseHomeComponent,
		ShowcaseAboutComponent,
		ShowcaseAssociationsComponent,
		ShowcaseChiefsComponent,
		ShowcaseChiefTalkComponent,
		ShowcaseContactComponent,
		ShowcaseFestivitiesComponent,
		ShowcaseGalleryComponent,
		ShowcaseHistoryComponent,
		// ShowcaseSecondaryNavComponent,
		ShowcaseGalleryVideosComponent,
		ShowcaseGalleryVideosViewComponent,
		ShowcaseGalleryPhotosComponent,
		ShowcaseGalleryPhotosAlbumComponent,
		ShowcaseFestivitiesF2007Component,
		ShowcaseFestivitiesF2017Component,
		ShowcaseFestivitiesF2010Component,
		ShowcaseFestivitiesF2009Component,
		SitesSettingsComponent,
    // DynamicFormComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
		BrowserAnimationsModule,
		SharedModule
  ],
  providers: [
		Title,
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 4000} }
	],
  bootstrap: [AppComponent]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
