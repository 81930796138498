import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { ShowcaseHeaderComponent } from './showcase/showcase-header/showcase-header.component';
import { ShowcaseFooterComponent } from './showcase/showcase-footer/showcase-footer.component';
import { ShowcaseHomeComponent } from './showcase/showcase-home/showcase-home.component';
import { ShowcaseAboutComponent } from './showcase/showcase-about/showcase-about.component';
import { ShowcaseAssociationsComponent } from './showcase/showcase-associations/showcase-associations.component';
import { ShowcaseChiefsComponent } from './showcase/showcase-chiefs/showcase-chiefs.component';
import { ShowcaseChiefTalkComponent } from './showcase/showcase-chief-talk/showcase-chief-talk.component';
import { ShowcaseContactComponent } from './showcase/showcase-contact/showcase-contact.component';
import { ShowcaseFestivitiesComponent } from './showcase/showcase-festivities/showcase-festivities.component';
import { ShowcaseFestivitiesF2007Component } from './showcase/showcase-festivities/showcase-festivities-f2007/showcase-festivities-f2007.component';
import { ShowcaseFestivitiesF2010Component } from './showcase/showcase-festivities/showcase-festivities-f2010/showcase-festivities-f2010.component';
import { ShowcaseFestivitiesF2017Component } from './showcase/showcase-festivities/showcase-festivities-f2017/showcase-festivities-f2017.component';
import { ShowcaseFestivitiesF2009Component } from './showcase/showcase-festivities/showcase-festivities-f2009/showcase-festivities-f2009.component';
import { ShowcaseGalleryComponent } from './showcase/showcase-gallery/showcase-gallery.component';
import { ShowcaseGalleryVideosComponent } from './showcase/showcase-gallery/showcase-gallery-videos/showcase-gallery-videos.component';
import { ShowcaseGalleryVideosViewComponent } from './showcase/showcase-gallery/showcase-gallery-videos/showcase-gallery-videos-view/showcase-gallery-videos-view.component';
import { ShowcaseGalleryPhotosComponent } from './showcase/showcase-gallery/showcase-gallery-photos/showcase-gallery-photos.component';
import { ShowcaseGalleryPhotosAlbumComponent } from './showcase/showcase-gallery/showcase-gallery-photos/showcase-gallery-photos-album/showcase-gallery-photos-album.component';
import { ShowcaseHistoryComponent } from './showcase/showcase-history/showcase-history.component';


const routes: Routes = [
	{ path: '', component: ShowcaseComponent, children: [
		{ path: '', component: ShowcaseHomeComponent },
		{ path: 'a-propos', component: ShowcaseAboutComponent },
		{ path: 'associations', component: ShowcaseAssociationsComponent },
		{ path: 'chefs-bangou', component: ShowcaseChiefsComponent },
		{ path: 'mot-du-chef', component: ShowcaseChiefTalkComponent },
		{ path: 'contact', component: ShowcaseContactComponent },
		{ path: 'festivites', component: ShowcaseFestivitiesComponent, children: [
			{ path: '2007', component: ShowcaseFestivitiesF2007Component },
			{ path: '2009', component: ShowcaseFestivitiesF2009Component },
			{ path: '2010', component: ShowcaseFestivitiesF2010Component },
			{ path: '2017', component: ShowcaseFestivitiesF2017Component },
		] },
		{ path: 'galerie', component: ShowcaseGalleryComponent, children: [
			{ path: 'videos', component: ShowcaseGalleryVideosComponent },
			{ path: 'videos/:video', component: ShowcaseGalleryVideosViewComponent },
			{ path: 'photos', component: ShowcaseGalleryPhotosComponent },
			{ path: 'photos/:album', component: ShowcaseGalleryPhotosAlbumComponent }
		] },
		{ path: 'notre-histoire', component: ShowcaseHistoryComponent },
		{ path: 'actualites', loadChildren: () => import('./showcase-news/showcase-news.module').then(mod => mod.ShowcaseNewsModule) },
	] },
	{ path: 'my-tontine', loadChildren: () => import('./showcase-tontine/showcase-tontine.module').then(mod => mod.ShowcaseTontineModule) },
	{ path: 'sites/:siteSlug', loadChildren: () => import('./sites/sites-showcase/sites-showcase.module').then(mod => mod.SitesShowcaseModule) },
	{ path: 'contact', component: ShowcaseContactComponent },
	{ path: '@', canActivate: [AuthGuard], loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule) },
	{ path: 'signin', component: SigninComponent },
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
		RouterModule.forRoot(routes,
		{
			// enableTracing: true, // <-- debugging purposes only
			preloadingStrategy: PreloadAllModules
		}
	)
	],
  exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
