import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-festivities-f2007',
  templateUrl: './showcase-festivities-f2007.component.html',
  styleUrls: ['./showcase-festivities-f2007.component.sass']
})
export class ShowcaseFestivitiesF2007Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
