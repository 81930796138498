import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../../sites/site.service';
import { PostService } from '../../posts/post.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormComponent } from '../../@widgets/dynamic-form/dynamic-form.component';
import { QuestionBase } from '../../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../../@widgets/dynamic-form/question-textbox';
import { FileQuestion } from '../../@widgets/dynamic-form/question-file';
import { getMediaSrc } from '../../../environments/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseActionService } from '../../@services/response-action.service';
import $ from 'jquery';

@Component({
  selector: 'app-posts-edit',
  templateUrl: './posts-edit.component.html',
  styleUrls: ['./posts-edit.component.sass']
})
export class PostsEditComponent implements OnInit {
  @ViewChild('dynForm', {static: false}) dynForm: DynamicFormComponent;
  site;
  post;
  questions:QuestionBase<any>[];
  processing;

  constructor(
    private siteService: SiteService,
    private responseAction: ResponseActionService,
    private postService: PostService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.postService.retreive(this.route.snapshot.paramMap.get('postSlug'))
      .subscribe((post: any) => {
        this.post = post;
        this.questions = [
          new TextboxQuestion({
            key: 'title',
            label: 'Titre',
            required: true,
            value: post.title
          }),
          new TextboxQuestion({
            key: 'content',
            label: 'Contenu',
            required: true,
            type: 'rte',
            value: post.content
          })
        ];
      });

    this.siteService.retreive(this.route.snapshot.paramMap.get('siteSlug'))
      .subscribe(site => {
        this.site = site;
      });
  }

  submit(event): void {
    this.dynForm.submitting = true;

    let form = event.form.value;

    this.postService.save(this.post.id, form, null, {tokenDomain: 'sites'})
      .subscribe(resp => {
        this.dynForm.submitting = false;
        this.responseAction.handle(resp);
      });
  }

}
