import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../site.service';
import { FormGroup } from '@angular/forms';
import { QuestionBase } from '../../@widgets/dynamic-form/question-base';
import { QuestionControlService } from '../../@widgets/dynamic-form/question-control.service';
import { isMobile, setToken, getToken } from '../../../environments/util';
import { UserService } from '../../users/user.service';
import { StaffService } from '../../staffs/staff.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { MatStepper } from '@angular/material/stepper';
import { DynamicFormComponent } from '../../@widgets/dynamic-form/dynamic-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileFormComponent } from '../../@widgets/file-form/file-form.component';
import { StaffFormComponent } from '../../@widgets/staff-form/staff-form.component';

@Component({
  selector: 'app-sites-new',
  templateUrl: './sites-new.component.html',
  styleUrls: ['./sites-new.component.sass'],
  providers: [ QuestionControlService ]
})
export class SitesNewComponent implements OnInit {
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @ViewChild('credentialsDynForm', {static: false}) credentialsDynForm: DynamicFormComponent;
  @ViewChild('basicDynForm', {static: false}) basicDynForm: DynamicFormComponent;
  @ViewChild('logoForm', {static: false}) logoForm: FileFormComponent;
  @ViewChild('coverForm', {static: false}) coverForm: FileFormComponent;
  @ViewChild('galleryForm', {static: false}) galleryForm: FileFormComponent;
  @ViewChild('staffForm', {static: false}) staffForm: StaffFormComponent;
  isMobile = isMobile;
  credentialsQuestions;
  basicQuestions;
  credentialsForm: FormGroup;
  basicForm: FormGroup;
  terms;
  credentialsFormBtns = [
    {text: 'Continuer', float: 'right', type: 'submit', action: 'submitCredentials'}
  ];
  basicFormBtns = [
    {text: 'Continuer', float: 'right', type: 'submit', action: 'submitBasic'},
    {text: 'Retour', type: 'button', action: 'goToPreviousStep', color: 'default'}
  ];
  siteSlug;
  submitting;
  // logo;
  // cover;
  // gallery;
  // services;
  // history;
  models = {
    logo: {label: 'logo', formName: 'logoForm'},
    cover: {label: 'l\'image de couverture', formName: 'coverForm'},
    gallery: {label: 'fichier de la galérie', formName: 'galleryForm'},
  };
  userId;
  siteId;
  // siteSlug="volkman-abbott-and-bradtke";

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private attachmentService: AttachmentService,
    private staffService: StaffService,
    private qcs: QuestionControlService,
    private siteService: SiteService) { }

  ngOnInit() {
    this.siteService.getQuestions()
      .then((_) => {
        let questions: any = _;
        this.terms = questions.terms;
        this.credentialsQuestions = questions.credentials;
        this.credentialsForm = this.qcs.toFormGroup(this.credentialsQuestions);
        this.basicQuestions = questions.basic;
        this.basicForm = this.qcs.toFormGroup(this.basicQuestions);
      });
  }

  storeStaff(data) {
    let model = data[0], value = data[1];

    this.staffService.store({authorId: this.userId, name: value.name, bio: value.bio, mugshot: value.mugshot, position: value.position, siteId: this.siteId})
      .subscribe(_ => {
        this.responseAction(_, model);
      });
  }

  deleteStaff(data) {
    let model = data[0], value = data[1];

    this.staffService.destroy(value)
      .subscribe(_ => {
        this.responseAction(_, model);
      });
  }

  responseAction(_, model) {
    let models = this.models;
    this[models[model].formName].processing = false;

    if (!_ || !_.success) {
      this.snackBar.open("Une erreur est survenue lors de l'enregistrement de "+ models[model].label +". Veuillez reéssayer.");
        this[models[model].formName].success = false;
      return console.log(_.message);
    }

    this.snackBar.open(models[model].label +" enrégistré avec succès");
      this[models[model].formName].success = true;
  }

  outputBtnAction(args) {
    this[args[0]](...args[1]);
  }

  goToPreviousStep(): void {
    this.stepper.previous();
  }

  submitCredentials(f) {
    this.credentialsDynForm.errorMsg = null;
    this.credentialsForm = f;
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  submitBasic(f) {
    this.basicDynForm.errorMsg = null;
    this.basicForm = f;
    this.stepper.selected.completed = true;
    this.grandSumit();
  }

  submittingEcho() {
    this.basicDynForm.submitting = true;
    this.credentialsDynForm.submitting = true;
  }

  submittingEchoOff() {
    this.basicDynForm.submitting = null;
    this.credentialsDynForm.submitting = null;
  }

  grandSumit() {
    this.submittingEcho();

    let userStoreErrorAction = (_ = null) => {
      this.snackBar.open(!_ ? "Une érreur est survenue lors de l'inscription de l'utilisateur" : _);
      this.stepper.previous();
      this.stepper.previous();
      this.submittingEchoOff();
    };
    let siteStoreErrorAction = (_ = null) => {
      this.snackBar.open(!_ ? "Une érreur est survenue lors de l'enregistrement de votre site" : _);
      this.stepper.previous();
      this.submittingEchoOff();
    };
    let userForm = this.credentialsForm.value;
    userForm['role'] = 'owner';
    userForm['targetDomain'] = 'sites';
    userForm['email'] = userForm.userEmail;
    delete userForm.userEmail;

    let storeSite = () => {
      let form = this.basicForm.value;

      form['author_id'] = this.userId;
      form['type'] = 'tontine';
      form['country'] = 'Cameroon';

      this.siteService.store(form, siteStoreErrorAction)
        .subscribe(_ => {
          this.submittingEchoOff(

          );
          if (!_ || !_.success) {
            //console.log()
            this.userService.destroy(this.userId, {tokenDomain: 'sites'}); // TODO::
            siteStoreErrorAction(_.message);
            console.log(_);
            return;
          }

          this.siteSlug = _.data.slug;
          this.siteId = _.data.id;
					userForm['targetId'] = 'sites';
					userForm['site_id'] = 'sites';
					this.userService.save(this.userId, {targetId: this.siteId, site_id: this.siteId});
          this.stepper.selected.completed = true;
          this.stepper.next();
        })
    };

    if (!this.userId) {
      this.userService.store(userForm, userStoreErrorAction, {noauth: true})
        .subscribe(_ => {
        if (!_.success) {
          this.credentialsDynForm.errorMsg = _.message;
          userStoreErrorAction(_.message);
          console.log(_);
          return;
        }

        this.userId = _.data.id;
        // console.log('user>>>>>>', _.data);
        setToken(_.data.token);
        storeSite();
      });
    }
    else
      storeSite();
  }

  moveOn() {
      this.router.navigate(['sites/'+ this.siteSlug]);
  }

}
