import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ResponseActionService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  handle(resp: any, finalAction = null, successAction = null, errorAction = null, successMsg = null, errorMsg = null) {
    if (finalAction)
      finalAction();
    if (!resp || !resp.success) {
      this.snackBar.open(errorMsg || (resp ? resp['message'] : "Une erreur est survenue"));
      if (errorAction)
        errorAction();
      return false;
    }

    this.snackBar.open(successMsg || "L'opération s'est déroulée avec succès");
    if (successAction)
      successAction();
    return true;
  }
}
