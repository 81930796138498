export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  placeholder: string;
	info: string;
	col: boolean;
  content: string;
  disabled: boolean;
  if: string;

  constructor(options: {
      value?: T,
      key?: string,
      label?: string,
      required?: boolean,
      order?: number,
      controlType?: string,
      placeholder?: string,
      info?: string,
      col?: boolean,
      content?: string,
      disabled?: boolean
      if?: string
    } = {}) {
    this.value = options.value;
    this.if = options.if;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.placeholder = options.placeholder || '';
    this.info = options.info || '';
    this.col = options.col || false;
    this.content = options.content || '';
    this.disabled = options.disabled || false;
  }
}
