import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor() { }

	getSecondaryMenu(user): any {
		let menu = [];

		switch(user.targetDomain) {
			case 'system':
				menu = menu.concat(
					[
						{ label: 'Associations', link: 'sites', icon: 'flag' },
						{ label: 'Utilisateurs', link: 'users', icon: 'group' },
						{ label: 'Actualités', link: 'news', icon: 'menu_book' }
					]
				);
				break;
			case 'sites':
				menu = menu.concat(
					[
						{ label: 'Tontines', link: 'inputs', icon: 'attach_money' },
						{ label: 'Prêts', link: 'loans', icon: 'local_atm' },
						{ label: 'Contributions', link: 'contributions', icon: 'local_mall' },
						{ label: 'Rapports réunions', link: 'reports', icon: 'library_books' },
						{ label: 'Sanctions', link: 'sanctions', icon: 'hardware' },
						{ label: 'Annonces', link: 'announcements', icon: 'rss_feed' },
						{ label: 'Parametres', link: 'settings', icon: 'settings' },
					]
				);

				if (['owner', 'admin'].indexOf(user.role) >= 0) {
					let oldMenu = menu

					menu = [
						{ label: 'Vitrine', link: 'showcase', icon: 'language', children: [
							{ link: 'basic', label: 'Basique' },
							{ link: 'medias', label: 'Médias' },
							{ link: 'staff', label: 'Staff' },
							{ link: 'customize', label: 'Personnaliser' },
							{ link: 'news', label: 'Actualités' },
							{ link: 'settings', label: 'Paramètres' }
						] },
						{ label: 'Membres', link: 'members', icon: 'group' },
					]

					menu = menu.concat(oldMenu)
				}

				break;
		}

		return menu;
	}
}
