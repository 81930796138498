import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowcaseGalleryService {
	photos: any = [
		{ slug: 'invitation-nouveau-chef', name: "Invitation nouveau chef", items: [
			{ caption: "", src: 'invitation-nouveau-chef-2.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-3.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-4.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-5.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-6.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-7.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-8.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-9.jpeg' },
			{ caption: "", src: 'invitation-nouveau-chef-10.jpeg' },
		] },
		{ slug: 'art', name: "Art", items: [
			{ caption: "", src: 'art4.gif' },
			{ caption: "", src: 'art2.gif' },
			{ caption: "", src: 'art1.gif' },
			{ caption: "", src: 'forge.gif' },
			{ caption: "", src: 'architecte.gif' },
			{ caption: "", src: 'roi.gif' },
			{ caption: "", src: 'legendes.gif' },
			{ caption: "", src: 'comices11.jpg' }
		] },
		{ slug: 'comices', name: "Comices", items: [
			{ caption: "", src: 'comices1.jpg' },
			{ caption: "", src: 'comices2.jpg' },
			{ caption: "", src: 'comices3.jpg' },
			{ caption: "", src: 'comices4.jpg' },
			{ caption: "", src: 'comices5.jpg' },
			{ caption: "", src: 'comices6.jpg' },
			{ caption: "", src: 'comices7.jpg' },
			{ caption: "", src: 'comices8.jpg' },
			{ caption: "", src: 'comices9.jpg' },
			{ caption: "", src: 'comices10.jpg' },
			{ caption: "", src: 'comices11.jpg' }
		] },
		{ slug: 'danse', name: "Danse", items: [
			{ caption: "", src: 'danse1.1.jpg' },
			{ caption: "", src: 'danse1.jpg' },
			{ caption: "", src: 'danse2.jpg' },
			{ caption: "", src: 'danse3.jpg' },
			{ caption: "", src: 'danse4.jpg' },
			{ caption: "", src: 'danse5.jpg' },
			{ caption: "", src: 'danse6.jpg' },
			{ caption: "", src: 'danse7.jpg' },
			{ caption: "", src: 'danse8.jpg' },
			{ caption: "", src: 'danse9.jpg' },
			{ caption: "", src: 'danse10.jpg' },
			{ caption: "", src: 'danse11.jpg' },
			{ caption: "", src: 'danse12.jpg' },
			{ caption: "", src: 'danse13.jpg' },
			{ caption: "", src: 'danse14.jpg' },
			{ caption: "", src: 'danse15.jpg' }
		] },
		{ slug: 'defile', name: "Défilé", items: [
			{ caption: "", src: 'conaba.jpg' },
			{ caption: "", src: 'defile.jpg' },
			{ caption: "", src: 'defile1.jpg' },
			{ caption: "", src: 'defile2.jpg' },
			{ caption: "", src: 'defile3.jpg' },
			{ caption: "", src: 'defile4.jpg' },
			{ caption: "", src: 'defile5.jpg' },
			{ caption: "", src: 'defile6.jpg' },
			{ caption: "", src: 'defile7.jpg' },
			{ caption: "", src: 'defile8.jpg' },
			{ caption: "", src: 'defile9.jpg' },
			{ caption: "", src: 'defile10.jpg' },
			{ caption: "", src: 'defile11.jpg' },
			{ caption: "", src: 'defile12.jpg' },
			{ caption: "", src: 'defile13.jpg' },
			{ caption: "", src: 'defile14.jpg' },
			{ caption: "", src: 'defile15.jpg' },
			{ caption: "", src: 'defile16.jpg' },
			{ caption: "", src: 'defile17.jpg' },
			{ caption: "", src: 'defile18.jpg' },
			{ caption: "", src: 'defile19.jpg' },
			{ caption: "", src: 'defile20.jpg' }
		] },
		{ slug: 'divers', name: "Divers", items: [
			{ caption: "", src: 'baobad.gif' },
			{ caption: "", src: 'forgerons.gif' },
			{ caption: "", src: 'danseur_seul.gif' },
			{ caption: "", src: 'mbete.gif' },
			{ caption: "", src: 'tenue.gif' },
			{ caption: "", src: 'forge.gif' },
			{ caption: "", src: 'chef1.gif' },
			{ caption: "", src: 'president.gif' },
			{ caption: "", src: 'danseur.gif' },
			{ caption: "", src: 'architecte.gif' },
			{ caption: "", src: 'roi.gif' },
			{ caption: "", src: 'legendes.gif' },
			{ caption: "", src: 'pasteur.gif' },
			{ caption: "", src: 'fontaine.gif' },
			{ caption: "", src: 'poteaux.gif' },
			{ caption: "", src: 'foyer.gif' },
			{ caption: "", src: 'bbdj.gif' },
			{ caption: "", src: 'invites.gif' },
			{ caption: "", src: 'medical.gif' },
			{ caption: "", src: 'ste_secrete_parade.gif' },
			{ caption: "", src: 'ste_secrete.gif' },
			{ caption: "", src: 'diplome.gif' },
			{ caption: "", src: 'trophet.gif' },
			{ caption: "", src: 'douala.gif' },
			{ caption: "", src: 'folklore.gif' },
			{ caption: "", src: 'tribune.gif' }
		] }
		// { slug: '', name: "", items: [
			// { caption: "", src: '' }
		// ] },
	];

	videos: any = [
		{ caption: "", src: 'danse.webm' }
	];

  constructor() { }
}
