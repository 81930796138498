import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, Compiler, Injector, ViewContainerRef } from '@angular/core';
import { AttachmentService } from '../../attachments/attachment.service';
import { SiteService } from '../../sites/site.service';
import { ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import $ from 'jquery';
import { getThumb, getMediaSrc } from '../../../environments/util';

@Component({
  selector: 'app-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.sass']
})
export class FileFormComponent implements OnInit {
  @ViewChild('container', {read: ElementRef, static: false}) container: ElementRef;
  @Input() model;
  @Input() width;
  @Input() files;
  @Input() data;
  @Input() multiple;
	@Output() changed = new EventEmitter<any>();
	@Output() removed = new EventEmitter<any>();
	@Output() captionUpdated = new EventEmitter<any>();
  processing;
  success;
  @Input() file;
  getThumb = getThumb;
  fileProcessing;
  stdGalleryItem = {caption: null, file: null};
  siteId;
  domain;

  constructor(
    private snackBar: MatSnackBar,
    private attachmentService: AttachmentService,
    private siteService: SiteService,
    private ref: ChangeDetectorRef) { }

  ngAfterViewInit() {
    let container = $(this.container.nativeElement);

    if (this.file)
      container.css({backgroundImage: 'url('+ getMediaSrc(this.file) +')'});

    if ('large' === this.width)
      container.css({maxWidth: '700px', width: '100%'});
  }

  ngOnInit() {
    if (this.multiple)
      this.attachmentService.where([['targetId', this.data.targetId, 'targetDomain', this.data.targetDomain]])
        .subscribe(_ => {
          this.files = _;
        });
  }

  storeAttachment(e) {
    this.processing = true;

    let file = {targetDomain: this.data.targetDomain, type: this.data['type'] || this.data.model || 'gallery', targetId: this.data['targetId'] || this.data.siteId, siteId: this.data.siteId, caption: '', file: e.target.files[0]};

    this.attachmentService.store(file, (_) => {
      this.snackBar.open('Failed saving attached');
    }, {noauth: true, tokenDomain: this.data.tokenDomain})
      .subscribe(resp => {
        this.processing = false;
        if (!resp || !resp.success) {
          this.snackBar.open('Une erreur est survenue')
          return;
        }
console.log('stored')
        this.files.push(resp.data);
      });
  }

  fileChanged(event, item = null, field = null) {
    if ('file' === field && event.target.files.length < 1) return;

    let file = 'caption' === field ? event.srcElement.value : event.target.files[0],
      values = {};

    values[this.data.model] = file;
    this.processing = true;
    if (!this.multiple)
      this.siteService.save(this.data.siteId, values)
        .subscribe(_ => {
          this.responseAction(_);

          $(this.container.nativeElement).css({backgroundImage: 'url('+ URL.createObjectURL(event.target.files[0]) +')'});
        });
    else {
      let data = 'caption' === field ? {caption: file} : {file: file};
    if (item)
      this.attachmentService.update(item.id, data, {noauth: true, tokenDomain: this.data.tokenDomain})
        .subscribe(_ => {
          this.responseAction(_);
console.log('saved')
          if('file' === field)
            $(event.srcElement).parent().css({backgroundImage: 'url('+ URL.createObjectURL(file) +')'});
        });
      else
        this.storeAttachment(event);
    }
  }

  removeFile(file) {
    this.processing = true;

    this.attachmentService.destroy(file.id, {_domain: 'sites'})
      .subscribe(_ => {
        this.processing = false;
        if (!_ || !_.success) {
          this.snackBar.open("L'image n'a pas pu être supprimée, bien vouloir reéssayer");
          return;
        }

        this.files.splice(this.files.indexOf(file), 1);
        this.processed();
      });
  }

  processed() {
    this.fileProcessing = null;
    this.processing = false;
  }

  onCaptionChange(item, ev) {
    this.captionUpdated.emit([item, ev]);
  }

  responseAction(_) {
    this.processing = false;

    if (!_ || !_.success) {
      this.snackBar.open("Une erreur est survenue");
      return console.log(_);
    }

    this.snackBar.open("L'opération s'est déroulée avec succès");
  }

}
