import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import $ from 'jquery'

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.sass']
})
export class ShowcaseComponent implements OnInit {

  constructor(
		private route: ActivatedRoute
	) { }

  ngOnInit() {
  }
	
	onActivate(ev) {
		let route = this.route
		
		$(function() {
			if ('/' === route.snapshot['_routerState'].url) {
				$('body').addClass('home')
			}
			else {
				$('body').removeClass('home')
			}
		})
	}

}
