import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'

import {PostService} from '../../posts/post.service'
import {getMediaSrc, getPrevUrl, cutText, excerpt, formatDate} from '../../../environments/util'

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.sass']
})
export class PostComponent implements OnInit {
  @Input() post
  getMediaSrc = getMediaSrc
  getPrevUrl = getPrevUrl
  cutText = cutText
  excerpt = excerpt
  formatDate = formatDate

  constructor(
    public postService: PostService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  async ngOnInit() {
  }

}
