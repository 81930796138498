import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string> {
  controlType = 'textbox';
  type: string;
  options: any;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';

    if (options['options'])
      this.options = options['options'];

		if (['textarea', 'rte', 'subheader'].indexOf(options['type']) >= 0)
			this.controlType = options['type'];
  }
}
