import { Component, OnInit, AfterViewInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-showcase-footer',
  templateUrl: './showcase-footer.component.html',
  styleUrls: ['./showcase-footer.component.sass']
})
export class ShowcaseFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }
	
	ngAfterViewInit () {
		$(function() {
			let $footer = $('#primayFooter'),
			$header = $('#primayHeader'),
			$body = $('body');
		
			// if ($body.outerHeight() < window.innerHeight) {
				// $footer.css({position: 'fixed', bottom: '0', width: '100%'});
			// }
		});
	}

}
