import { Component, OnInit } from '@angular/core';
import { ShowcaseGalleryService } from '../../showcase-gallery.service'
import { Routes, Router, ActivatedRoute } from '@angular/router'
import $ from 'jquery'

@Component({
  selector: 'app-showcase-gallery-photos-album',
  templateUrl: './showcase-gallery-photos-album.component.html',
  styleUrls: ['./showcase-gallery-photos-album.component.sass']
})
export class ShowcaseGalleryPhotosAlbumComponent implements OnInit {
	album
	
  constructor(
		private galleryService: ShowcaseGalleryService,
		private route: ActivatedRoute
	) { }

  ngOnInit() {
		let albumSlug = this.route.snapshot.paramMap.get('album')
		this.galleryService.photos.forEach((item) => {
			if (item.slug === albumSlug)
				this.album = item
		})
  }
	
	zoomPic(src) {
		let block = $('<div></div>'),
			holder = $('<div></div>'),
			img = $('<img alt="" style="opacity: 0; position: relative; top: 20px" src="" />')
		
		block.click(() => block.remove())
		
		block.css({zIndex: '1150', position: 'fixed', background: 'rgba(0, 0, 0, 0.7)', cursor: 'pointer', height: '100%', width: '100%', top: '0', left: '0'})
		holder.css({position: 'absolute', cursor: 'pointer'})
		
		img.attr('src', src)
		holder.append(img)
		block.append(holder)
		$('body').append(block)
		
		let imgIniWidth = img[0].offsetWidth,
			imgIniHeight = img[0].offsetHeight,
			padding = 20,
			imgNewHeight = (window.innerHeight - padding) > imgIniHeight ? imgIniHeight : (window.innerHeight - padding),
			imgNewWidth = imgNewHeight * imgIniWidth / imgIniHeight
		
		img.css({height: imgNewHeight +'px', width: imgNewWidth +'px'})
		holder.css({top: ((window.innerHeight - imgNewHeight - padding) / 2) +'px', left: ((window.innerWidth - imgNewWidth - padding) / 2) +'px'})
		
		img.animate({opacity: 1, top: '0px'})
	}

}
