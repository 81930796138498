import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AuthService } from '../../auth/auth.service';
import { AdminService } from '../admin.service'

@Component({
  selector: 'app-admin-secondary-nav-panel',
  templateUrl: './admin-secondary-nav-panel.component.html',
  styleUrls: ['./admin-secondary-nav-panel.component.sass']
})
export class AdminSecondaryNavPanelComponent implements OnInit {
	list;
	user
	
	constructor(
		private authService: AuthService,
    private _bottomSheetRef: MatBottomSheetRef<AdminSecondaryNavPanelComponent>,
		private adminService: AdminService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
	) {
		
	}

  ngOnInit() {
		this.authService.check().then((user: any) => {
			this.user = user;
			this.list = this.adminService.getSecondaryMenu(user)
		});
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }


}
