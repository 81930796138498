import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../auth.service';
import { setToken, setCookie } from '../../../environments/util';
import { ResponseActionService } from '../../@services/response-action.service';
import {SiteService} from '../../sites/site.service'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.sass']
})
export class SigninComponent implements OnInit {
  formProcessing;
  form: any = {siteSlug: ""};
  errorMsg;
  sites

  constructor(
    private responseAction: ResponseActionService,
		private route: ActivatedRoute,
		private router: Router,
    private authService: AuthService,
    private siteService: SiteService,
  ) { }

  async ngOnInit() {
    this.sites = await this.siteService.list().toPromise().then()
  }

  submit(): void {
    if (!this.form['pseudo']) {
      this.errorMsg = "Veuillez renseigner votre nom d'utilisateur"
      return
    }

    if (!this.form['password']) {
      this.errorMsg = "Veuillez renseigner votre mot de passe"
      return
    }

    if (!this.form['siteSlug']) {
      this.errorMsg = "Veuillez selectionner votre association"
      return
    }

    this.formProcessing = true;
    this.form.pseudo = this.form.siteSlug +"-"+ this.form.pseudo

		this.authService.authenticate(
      this.form,
      err => this.responseAction.handle(err, () => {
        this.formProcessing = false
        this.form.pseudo = this.form.pseudo.replace((this.form.siteSlug +"-"), "")
        this.errorMsg = ""
      }))
			.subscribe(r => {
				this.responseAction.handle(r, () => this.formProcessing = false, () => {
				  setToken(r.data.apiToken);

					let redirect = this.route.snapshot.paramMap.get('redirect');

				  this.router.navigate([redirect || '/@']);
				}, null, "Vous êtes maintenant connecté", r.message);
			});
  }
}
