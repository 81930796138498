import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { StaffService } from '../../staffs/staff.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { QuestionBase } from '../dynamic-form/question-base';
import { TextboxQuestion } from '../dynamic-form/question-textbox';
import { FileQuestion } from '../dynamic-form/question-file';
import { getMediaSrc } from '../../../environments/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseActionService } from '../../@services/response-action.service'


@Component({
  selector: 'app-staff-form',
  templateUrl: './staff-form.component.html',
  styleUrls: ['./staff-form.component.sass']
})
export class StaffFormComponent implements OnInit {
  @ViewChild('dynForm', {static: false}) dynForm: DynamicFormComponent;
  @Input() data;
  @Input() flex;
  getMediaSrc = getMediaSrc;
	// @Output() changed = new EventEmitter<any>();
  items = [];
  questions:QuestionBase<any>[] = [
    new FileQuestion({
      key: 'mugshot'
    }),
    new TextboxQuestion({
      key: 'name',
      label: 'Nom(s) et prenom(s)',
      required: true
    }),
    new TextboxQuestion({
      key: 'position',
      label: 'Poste',
      required: true
    }),
    new TextboxQuestion({
      key: 'bio',
      label: 'Bio',
      type: 'textarea'
    })
  ];
  processing;
  list: any[] = [];

  constructor(
    private staffService: StaffService,
    private snackBar: MatSnackBar,
    private responseAction: ResponseActionService,
  ) { }

  ngOnInit() {
    // this.staffService.list(this.data.targetDomain, this.data.targetId)
      // .subscribe(resp => {
        // this.list = resp;
      // })
  }

  store(event) {
    this.dynForm.submitting = true;
    let form = event.value;
    // form['mugshot'] = this.dynForm.file;
    form['targetDomain'] = this.data.targetDomain;
    form['targetId'] = this.data.targetId;
    form['siteId'] = this.data.targetId;

    this.staffService.store(form, null, {tokenDomain: this.data.tokenDomain})
      .subscribe(resp => {
        this.dynForm.submitting = false;
        if (this.responseAction.handle(resp))
          this.list.push(resp.data);
          this.dynForm.reset();
      })
  }

  destroy(item) {
    this.staffService.destroy(item.id, {tokenDomain: this.data.tokenDomain})
      .subscribe(resp => {
        if (this.responseAction.handle(resp))
          this.list.splice(this.list.indexOf(item), 1);
      })
  }

}
