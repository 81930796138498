import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Router, ActivatedRoute } from '@angular/router'
import { MemberService } from '../../../members/member.service'
import { formatDate } from '../../../../environments/util'

@Component({
  selector: 'app-admin-membres-view',
  templateUrl: './admin-membres-view.component.html',
  styleUrls: ['./admin-membres-view.component.sass']
})
export class AdminMembresViewComponent implements OnInit {
	civilIdentity
	villageIdentity
	associationIdentity
	self
	data: any = {}

	constructor(
		// @Inject(MAT_DIALOG_DATA) public data: any,
		private router: Router,
		private route: ActivatedRoute,
		private memberService: MemberService,
	) { }

  ngOnInit() {
		let proceed = (self) => {
			this.self = self

			this.civilIdentity = [
				{ term: "Nom & Prénom(s)", definition: `${self.firstName} ${self.lastName}` },
				{ term: "Date de naissance", definition: formatDate(self.birthDate) },
				{ term: "Lieu de naissance", definition: self.birthPlace },
				{ term: "Numéro(s) de téléphone", definition: self.tel },
				{ term: "WhatsApp", definition: self.whatsapp },
				{ term: "Email", definition: self.email },
				{ term: "Proféssion", definition: self.profession },
				{ term: "Situation familiale", definition: !self.married ? '-' : ('y' === self.married ? 'Oui' : 'Non') },
				{ term: "Nombre de femmes", definition: self.nbrOfSpouses },
				{ term: "Nombre d'enfants", definition: self.children },
				{ term: "Ville", definition: self.city },
        { term: "Quartier/Rue", definition: self.cityStreetName },
        { term: "Adresse", definition: self.cityAddress }
			]

			this.villageIdentity = [
				{ term: "Village", definition: self.village },
				{ term: "Quartier/Canton", definition: self.villageStreetName },
				{ term: "Adresse", definition: self.villageAddress },
				{ term: "Chef de famille / Successeur", definition: !self.householder ? "-" : ("y" === self.householder ? "Oui" : "Non") },
				{ term: "Titre de notabilité", definition: self.villagePosition },
				{ term: "Nom associé au titre", definition: self.villagePositionLabel },
				{ term: "Date d'obtention du titre", definition: formatDate(self.villagePositionDate) }
			]

			this.associationIdentity = [
				{ term: "Poste dans l'association", definition: self.assocPosition },
				{ term: "Date d'obtention du poste", definition: formatDate(self.assocPositionDate) },
				{ term: "Date d'adhésion", definition: formatDate(self.subscriptionDate) },
				{ term: "Autres associations liées", definition: "" }
			]
		}

		if (!this.data || !this.data.hasOwnProperty('self'))
			this.memberService.where([['slug', this.route.snapshot.paramMap.get('memberSlug')]], {first: true})
				.subscribe((member: any) => proceed(member))
		else
			proceed(this.data.self)
  }

}
