import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-history',
  templateUrl: './showcase-history.component.html',
  styleUrls: ['./showcase-history.component.sass']
})
export class ShowcaseHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
