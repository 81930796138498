import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sites-settings',
  templateUrl: './sites-settings.component.html',
  styleUrls: ['./sites-settings.component.sass']
})
export class SitesSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
