import { Component, Input, EventEmitter, OnInit, Output, ElementRef, ViewChild }  from '@angular/core';
import { FormGroup }                 from '@angular/forms';

import { QuestionBase }              from './question-base';
import { QuestionControlService }    from './question-control.service';
import $ from 'jquery';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {
  @ViewChild('fileContainer', {read: ElementRef, static: false}) fileContainer: ElementRef;
	@Input() buttons;
  @Input() questions: QuestionBase<any>[] = [];
	@Output() submitted = new EventEmitter<any>();
	@Output() clicked = new EventEmitter<any>();
	@Output() fieldChanged = new EventEmitter<any>();
	@Output() fieldKeyedUp = new EventEmitter<any>();
  @Input() form: any;
  payLoad = '';
	errorMsg;
	submitting: boolean;
  files: any = {};
	rte: any = {};

  constructor(private qcs: QuestionControlService) {  }

  ngOnInit() {
		this.questions.forEach((q) => {
			if ('rte' === q['type'])
				this.rte = q.key;
		});

    if (!this.form)
		  this.form = this.qcs.toFormGroup(this.questions);
  }

  reset() {
    this.form.reset();

    for(let key in this.rte) {
      if (!this.rte.hasOwnProperty(key)) continue;

      $('#fileContainer_'+ key).css({backgroundImage: 'none'});
    }

    for(let key in this.files) {
      if (!this.files.hasOwnProperty(key)) continue;

      $('#rteFormField_'+ key).find('.e-content').html('');
    }

    this.files = {};
    this.rte = {};
  }

  removeFile(data) {
    let model = data[0], file = data.length > 1 ? data[1] :  null;
    //
    // if (!file) {
    //   delete this.files[model];
    //   return;
    // }

    this.files[model].splice(this.files[model].indexOf(file), 1);
  }

  isFile(model, file = null) {
    if (!file) {
      return !!this.files[model];
    }

    return this.files[model].indexOf(file) >= 0;
  }

  fileChanged(data) {
    let event = data[0], model = data[1], multiple = data.length > 2 ? data[2] : false;

    if (event.target.files.length < 1 && !multiple) {
      $('#fileContainer_'+ model).css({backgroundImage: 'none'});
      delete this.files[model];
      return;
    }

    if (!multiple) {
      this.files[model] = event.target.files[0];

      $('#fileContainer_'+ model).css({backgroundImage: 'url('+ URL.createObjectURL(event.target.files[0]) +')'});
    }
    else {
      if (!this.files[model])
        this.files[model] = [];

      this.files[model].push(event.target.files[0]);
    }

    this.form.value[model] = this.files[model];
  }

  onSubmit() {
    // this.payLoad = JSON.stringify(this.form.value);
    for(let key in this.files) {
      if (!this.files.hasOwnProperty(key)) continue;

      this.form.value[key] = this.files[key];
    }

		for(let key in this.rte) {
      if (!this.rte.hasOwnProperty(key)) continue;

      this.form.value[key] = $('#rteFormField_'+ this.rte[key]).find('.e-content').html();
    }
// console.log(this.form)
		this.submitted.emit(this);
  }

	btnClick(btn) {
		if (!btn.hasOwnProperty('action')) return;
		if('string' === typeof btn.action)
			this.clicked.emit([btn.action, [this.form]]);

		// if (btn.hasOwnProperty('actionArgs')) {
			// btn.actionArgs.push(this.form);
      // btn.action(...btn.actionArgs);
		// }
    // else
      // btn.action(this.form);
	}
}
