import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent implements OnInit {
  @Input() data: any;
  type: string;

  constructor() { }

  ngOnInit() {
    if (this.data)
      this.type = this.data.hasOwnProperty('type') && 'inline' === this.data.type ? 'inline' : '';
  }

}
