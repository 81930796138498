import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'thumbnail-profile',
  templateUrl: './thumbnail-profile.component.html',
  styleUrls: ['./thumbnail-profile.component.sass']
})
export class ThumbnailProfileComponent implements OnInit {
  @Input() profile: any;

  constructor() { }

  ngOnInit() {

  }

}
