import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from '../users/user.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { getToken, setToken, apiUrl, formData, destroyToken } from '../../environments/util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user;
  isLoggedIn;
	redirectUrl: string;
  htmlHttpOptions = {
    headers: new HttpHeaders({
      'Accept': 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'text/html'//'application/x-www-form-urlencoded'
    }),
    responseType: 'html'
  };

  constructor(
    private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
    private userService: UserService
  ) {}

	authenticate(form: Object, reject = null): Observable<any> { 
		return this.http.post<any>(apiUrl +'signin', formData(form))
      .pipe(
        tap(r => this.isLoggedIn = true),
				catchError(this.handleError<any>('error on:: signing in', reject))
			);
	}

  logOut(): void {
		destroyToken();
    this.isLoggedIn = false;
		this.router.navigate(['/']);
  }

  protected handleError<T> (operation = 'operation', reject = null, result?: T) {
    return (error: any): Observable<T> => {
      if (reject)
        reject(error.message);

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

	check(targetDomain = null, targetId = null, requiredRoles = []) {
    return new Promise(async (resolve, reject) => {
      let token = getToken(), target;

      if (!token && reject) 
				return reject('NO_TOKEN');

      let user = await this.userService.retreive(token, true);

      if ('sysAdmin' === user.role) {
        this.isLoggedIn = true;
        return resolve(user);
      }
      
      if (!user)
        return reject('WRONG_TOKEN');

      if (targetDomain && targetDomain !== user.targetDomain)
        return reject('WRONG_TARGETDOMAIN');

      if (targetId && targetId !== user.targetId)
        return reject('WRONG_TARGETID');

      if (requiredRoles.length > 0 && requiredRoles.indexOf(user.role) < 0)
        return reject('WRONG_ROLE');

      this.isLoggedIn = true;

      return resolve(user);
    });
  }

  retreive(targetDomain = null, requiredRoles = []) {
    return this.check(targetDomain, requiredRoles);
  }
}
