import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { apiUrl, formData, watch } from '../../environments/util';
import { SiteService } from '../sites/site.service';
import { ModelService } from '../@services/model.service';
import { Router, ActivatedRoute } from '@angular/router'
import { QuestionBase } from '../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../@widgets/dynamic-form/question-textbox';
import { DropdownQuestion } from '../@widgets/dynamic-form/question-dropdown';
import { DateQuestion } from '../@widgets/dynamic-form/question-date';
import { AutocompleteQuestion } from '../@widgets/dynamic-form/question-autocomplete';
import { CheckboxQuestion } from '../@widgets/dynamic-form/question-checkbox';
import { RadioQuestion } from '../@widgets/dynamic-form/question-radio';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends ModelService {

  constructor(
    public http: HttpClient,
    public router: Router,
    private siteService: SiteService
  ) {
    super(http, router);
    this.domain = 'members';
  }

	getQuestions (key = null) {
		return new Promise((resolve, reject) => {
      let member, sitesOptions, pipe,
			   proceed = () => {
        let el: any = member

				return resolve([
					new TextboxQuestion({
						key: 'idCivilSubheader',
						label: 'Identité civile',
						type: 'subheader'
					}),
					new TextboxQuestion({
						key: 'firstName',
						label: 'Nom',
						required: true,
						value: el ? el.firstName : '',
						col: true
					}),
					new TextboxQuestion({
						key: 'lastName',
						label: 'Prénoms(s)',
						required: true,
						value: el ? el.lastName : '',
						col: true
					}),
					new DateQuestion({
						key: 'birthDate',
						label: 'Date de naissance',
						// required: true,
						value: el ? el.birthDate : ''
					}),
					new TextboxQuestion({
						key: 'birthPlace',
						label: 'Lieu de naissance',
						// required: true,
						value: el ? el.birthPlace : ''
					}),
					new TextboxQuestion({
						key: 'tel',
						label: 'Numéro(s) de téléphone',
						// required: true,
						value: el ? el.tel : ''
					}),
					new TextboxQuestion({
						key: 'whatsapp',
						label: 'Numéro(s) WhatsApp',
						// required: true,
						value: el ? el.tel : ''
					}),
					new TextboxQuestion({
						key: 'email',
						label: 'Email',
						// required: true,
						value: el ? el.email : ''
					}),
					new TextboxQuestion({
						key: 'profession',
						label: 'Proféssion',
						// required: true,
						value: el ? el.profession : ''
					}),
					new DropdownQuestion({
						key: 'married',
						label: 'Marrié',
						options: [
							{key: 'y', value: 'Oui'},
							{key: 'n', value: 'Non'},
						],
						value: el ? el.married : ''
					}),
					new TextboxQuestion({
						key: 'nbrOfSpouses',
						label: 'Nombre de femmes',
						type: 'number',
						value: el ? el.nbrOfSpouses : ''
					}),
					new TextboxQuestion({
						key: 'children',
						label: 'Nombre d\'enfants',
						type: 'number',
						value: el ? el.children : ''
					}),
					new TextboxQuestion({
						key: 'city',
						label: 'Ville',
						// required: true,
						value: el ? el.city : ''
					}),
					new TextboxQuestion({
						key: 'cityStreetName',
						label: 'Quartier/Rue',
						// required: true,
						value: el ? el.cityStreetName : ''
					}),
					new TextboxQuestion({
						key: 'cityAddress',
						label: 'Adresse',
						// required: true,
						value: el ? el.cityAddress : ''
					}),
					new TextboxQuestion({
						key: 'idVillagerSubheader',
						label: 'Identité villageoise',
						type: 'subheader'
					}),
					new TextboxQuestion({
						key: 'village',
						label: 'Nom du village',
						// required: true,
						value: el ? el.village : ''
					}),
					new TextboxQuestion({
						key: 'villageStreetName',
						label: 'Quartier/Canton',
						// required: true,
						value: el ? el.villageStreetName : ''
					}),
					new TextboxQuestion({
						key: 'villageAddress',
						label: 'Adresse',
						// required: true,
						value: el ? el.cityAddress : ''
					}),
					new DropdownQuestion({
						key: 'householder',
						label: 'Chef de famille / Successeur',
						options: [
							{key: 'y', value: 'Oui'},
							{key: 'n', value: 'Non'},
						],
						value: el ? el.married : ''
					}),
					new TextboxQuestion({
						key: 'villagePosition',
						label: 'Titre de notabilité',
						// required: true,
						value: el ? el.villagePosition : ''
					}),
					new TextboxQuestion({
						key: 'villagePositionLabel',
						label: 'Nom associé au titre',
						// required: true,
						value: el ? el.villagePositionLabel : ''
					}),
					new DateQuestion({
						key: 'villagePositionDate',
						label: 'Date d\'obtention du titre',
						// required: true,
						value: el ? el.villagePositionDate : ''
					}),
					new TextboxQuestion({
						key: 'idAssocSubheader',
						label: 'Association',
						type: 'subheader'
					}),
					new TextboxQuestion({
						key: 'assocPosition',
						label: 'Poste dans l\'association',
						// required: true,
						value: el ? el.assocPosition : ''
					}),
					new DateQuestion({
						key: 'assocPositionDate',
						label: 'Date d\'obtention du poste',
						// required: true,
						value: el ? el.assocPositionDate : ''
					}),
					new DateQuestion({
						key: 'subscriptionDate',
						label: 'Date d\'adhésion',
						// required: true,
						value: el ? el.subscriptionDate : ''
					}),
					new TextboxQuestion({
						key: 'otherAssocs',
						label: 'Autres associations',
						type: 'multi-autocomplete',
            options: sitesOptions,
						// required: true,
						value: el ? el.otherAssocs : ''
					})
				])
			}

      this.siteService.where([['id', '>', '0']]).subscribe(r => {
        sitesOptions = r.map((site: any) => { return {value: site.id, label: site.name} })
      })

			if (key)
				pipe = watch(() => member && sitesOptions)
			else
				pipe = watch(() => sitesOptions)

      pipe.then(() => proceed())
		})
	}
}
