import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { getThumb, getMediaSrc, isMobile, ispartof, getobjectof } from '../../../environments/util';
import  $ from 'jquery';

@Component({
  selector: 'app-showcase-header',
  templateUrl: './showcase-header.component.html',
  styleUrls: ['./showcase-header.component.sass']
})
export class ShowcaseHeaderComponent implements OnInit {
  getThumb = getThumb;
  getMediaSrc = getMediaSrc;
  menuIsOpened;
  $ = $;
  user;
	authChecked;
	authResponse;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
		this.authService.check()
			.then(r => {
				this.authChecked = true;
				this.user = r;
			}, r => {
				this.authChecked = true;
				this.authResponse = r;
			});
		
    $(function() {
      let $menu = $('#showcaseNavbarCollapse');
      $menu.find('.nav-link').click(() => {if(isMobile()) $menu.hide();});
    });
  }

}
