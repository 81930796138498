import { QuestionBase } from './question-base';

export class DateQuestion extends QuestionBase<string> {
  controlType = 'date';
  options: any;

  constructor(options: {} = {}) {
    super(options);
		this.options = options['otions'] || [];
  }
}
