import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../../sites/site.service';
import { AuthService } from '../../auth/auth.service'
import { PostService } from '../../posts/post.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormComponent } from '../../@widgets/dynamic-form/dynamic-form.component';
import { QuestionBase } from '../../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../../@widgets/dynamic-form/question-textbox';
import { FileQuestion } from '../../@widgets/dynamic-form/question-file';
import { getMediaSrc } from '../../../environments/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseActionService } from '../../@services/response-action.service';
import $ from 'jquery';

@Component({
  selector: 'app-posts-new',
  templateUrl: './posts-new.component.html',
  styleUrls: ['./posts-new.component.sass']
})
export class PostsNewComponent implements OnInit {
  @ViewChild('dynForm', {static: false}) dynForm: DynamicFormComponent;
  site;
  questions:QuestionBase<any>[] = [
    new TextboxQuestion({
      key: 'title',
      label: 'Titre',
      required: true
    }),
    new TextboxQuestion({
      key: 'content',
      label: 'Contenu',
      type: 'rte'
    }),
    new FileQuestion({
      key: 'attachments',
      label: 'Ilustration',
      type: 'multiple'
    })
  ];
  processing;
  list: any[] = [];
	user

  constructor(
    private siteService: SiteService,
    private responseAction: ResponseActionService,
    private postService: PostService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
		private auth: AuthService,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
		await this.auth.check().then(r => this.user = r)
  }

  submit(event): void {
    this.dynForm.submitting = true;
// console.log("ev>>>>>>>>>>>>>>>>",event)
    let form = event.form.value;
		let siteId = this.user.targetDomain !== 'system' ? this.user.targetId : 0

    form['userId'] = this.user.id;
    form['siteId'] = siteId;
    form['targetId'] = siteId;
    form['targetDomain'] = 'blog';

    this.postService.store(form, null, {tokenDomain: 'sites'})
      .subscribe((resp: any) => {
        if (!resp || !resp.success) {
          this.dynForm.submitting = false;
          return this.responseAction.handle(resp);
        }

        form.attachments.forEach(file => {
          this.storeAttachment(file, resp.data.id)
        });

        // this.attachmentService.save(0, {targetId: siteId, targetDomain: 'blog', attachments: form.attachments, old_files: [], ids: []}, (_) => {console.log(_)}, {noauth: true, tokenDomain: 'sites'})
        //   .subscribe((attached: any) => {
        //     this.dynForm.submitting = false;
        //
        //     if (attached && attached.success) {
        //       this.dynForm.reset();
        //       this.responseAction.handle(attached);
        //     }
        //     else {
        //       this.responseAction.handle(attached);
        //     }
        //   });
      });
  }

  storeAttachment(file, siteId) {
    let file_ = {targetDomain: 'posts', type: null, targetId: siteId, siteId: siteId, caption: '', file: file};

    this.attachmentService.store(file_, (_) => {
      this.snackBar.open('Failed saving attached');
    }, {noauth: true/*, tokenDomain: this.data.tokenDomain*/})
      .subscribe(resp => {
        if (!resp || !resp.success) {
          this.snackBar.open('Une erreur est survenue')
          return;
        }
console.log('stored')
      });
  }

}
