import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-sample',
  templateUrl: './ad-sample.component.html',
  styleUrls: ['./ad-sample.component.sass']
})
export class AdSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
