import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.sass']
})
export class StatusMessageComponent implements OnInit {
  @Input() data;
  theme: any = {};
  icon;
  message;

  constructor() {

  }

  ngOnInit() {
    let theme = !this.data.hasOwnProperty('theme') ? 'default' : this.data.theme;
    switch(theme) {
      case 'success':
        this.theme['color'] = "#2d8800";
        this.icon = 'golf_course';
        this.message = "L'opération s'est déroulée avec succès";
        break;
      case 'empty':
        this.theme['color'] = "#8396bc";
        this.icon = 'cloud_off';
        this.message = "Aucune entrée";
        break;
      default:
        this.theme['color'] = "#2559ac";
        this.icon = 'priority_high';
    }

    if (this.data.hasOwnProperty('message'))
      this.message = this.data.message;
    if (this.data.hasOwnProperty('icon'))
      this.icon = this.data.icon;
  }

}
