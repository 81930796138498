import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router'

import {PostService} from '../../posts/post.service'
import {getThumb, excerpt} from '../../../environments/util'

@Component({
  selector: 'app-showcase-secondary-nav',
  templateUrl: './showcase-secondary-nav.component.html',
  styleUrls: ['./showcase-secondary-nav.component.sass']
})
export class ShowcaseSecondaryNavComponent implements OnInit {
  getThumb = getThumb
  excerpt = excerpt
  posts

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public postService: PostService
  ) { }

  ngOnInit() {
    if (this.router.url.indexOf('actualites') < 0)
      this.postService.where([['targetId', 0], ['targetDomain', 'blog']])
        .subscribe(posts => this.posts = posts.slice(2))
  }

}
