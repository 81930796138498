import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { cutText, getThumb } from '../../../../environments/util';
import { AttachmentService }  from '../../../attachments/attachment.service';
import { PostService }  from '../../../posts/post.service';
import { AuthService }  from '../../../auth/auth.service';
import { ResponseActionService }  from '../../../@services/response-action.service';

@Component({
  selector: 'app-post-thumb',
  templateUrl: './post-thumb.component.html',
  styleUrls: ['./post-thumb.component.sass']
})
export class PostThumbComponent implements OnInit {
  @Output() deleted = new EventEmitter<any>();
  @Input() post;
  @Input() type;
  @Input() sref;
  @Input() srefParams;
  @Input() dashboardMode;
  cutText = cutText;
  getThumb = getThumb;
  cover;
  user;
	excerpt;

  constructor(
    private attachmentService: AttachmentService,
    private postService: PostService,
    private responseAction: ResponseActionService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.auth.check('sites', ['sysAdmin']).then((user) => this.user = user, (_) => console.log(_));
    this.attachmentService.where([['targetId', this.post.id], ['targetDomain', 'posts']])
      .subscribe(attachments => {
        this.cover = attachments.length > 0 ? getThumb(attachments[0].src) : null;
				this.excerpt = cutText(this.post.content.replace(/<[^>]*>?/gm, '').replace(['#', '*'], ['', '']), 300);
      });
  }

  deletePost(post): void{
    this.postService.destroy(post.id, {tokenDomain: 'sites'})
      .subscribe(resp => {
        this.responseAction.handle(resp);
        if (resp && resp.success) {
          this.deleted.emit(post);
        }
      })
  }

}
