import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { apiUrl, getToken } from '../../environments/util';
import { ModelService } from '../@services/model.service';
import { Router, ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class UserService extends ModelService {

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
    this.domain = 'users'
  }

	authenticate(form: Object, reject = () => {}): Observable<any> {
		return this.http.post<any>(apiUrl +'signin', form)
      .pipe(
				retry(5),
        tap(_ => console.log('signed & fetched user'))
      );
	}

	retreive(token, sync = false): any {
		return !sync ? this.http.get<any>(apiUrl +'authorized?apiToken='+ token).pipe(retry(5))
      : new Promise((resolve, reject) => this.http.get<any>(apiUrl +'authorized?apiToken='+ token).pipe(retry(5)).subscribe(_ => resolve(_)));
	}
}
