import { Component, OnInit, Input } from '@angular/core';
import { getThumb } from '../../../environments/util';

@Component({
  selector: 'mugshot',
  templateUrl: './mugshot.component.html',
  styleUrls: ['./mugshot.component.sass']
})
export class MugshotComponent implements OnInit {
  @Input() data;
  getThumb = getThumb;
  pictureReplacement: any;
  picture;
  name;

  constructor() {

  }

  ngOnInit() {
		// this.data.picture = !this.data.picture ? '' : URL.createObjectURL(new Blob([data.picture]));
    let size = !this.data.size ? 250 : this.data.size,
			textSize = size * 0.5;

    if (this.data.hasOwnProperty('mugshot') && this.data['mugshot'])
      this.picture = getThumb(this.data.mugshot);

    if (this.data.hasOwnProperty('picture') && this.data['picture'])
      this.picture = getThumb(this.data.picture);

    this.name = this.data.name;
    
    this.pictureReplacement = {
			text: this.name.split(' ').map(str => str.substring(0, 1)).slice(0, 2).join('').toUpperCase(),
			size: size,
			textSize: textSize
		};
  }

}
