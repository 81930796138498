import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '../../@widgets/dynamic-form/dynamic-form.component';
import { TextboxQuestion } from '../../@widgets/dynamic-form/question-textbox';
import { QuestionControlService } from '../../@widgets/dynamic-form/question-control.service';
import { ResponseActionService } from '../../@services/response-action.service';

@Component({
  selector: 'app-showcase-contact',
  templateUrl: './showcase-contact.component.html',
  styleUrls: ['./showcase-contact.component.sass'],
  providers: [ QuestionControlService ]
})
export class ShowcaseContactComponent implements OnInit {
	@ViewChild('dynForm', {static: false}) dynForm: DynamicFormComponent;
  questions = [
    new TextboxQuestion({
      label: 'Nom, prenom(s)',
      key: 'name',
      required: true
    }),
    new TextboxQuestion({
      label: 'Numero de téléphone',
      key: 'tel',
      col: true,
      required: true
    }),
    new TextboxQuestion({
      label: 'Adresse email',
      key: 'email',
      col: true
    }),
    new TextboxQuestion({
      label: 'Votre message',
      key: 'content',
      type: 'textarea',
      required: true
    })
  ];

  constructor(
    private responseAction: ResponseActionService,
	) { }

  ngOnInit() {
  }

  submit(event): void {
    // this.dynForm.submitting = true;
    // let form = event.value;
    // form['site_id'] = this.site.id;
    // this.pageService.store(form, (_) => this.dynForm.submitting = false, {tokenDomain: 'sites'})
    //   .subscribe(resp => {
    //     this.dynForm.submitting = false;
    //     this.responseAction.handle(resp);
    //     if (resp && resp.success)
    //       event.reset();
    //   })
  }

}
