import { Component, OnInit } from '@angular/core';
import { ShowcaseGalleryService } from '../showcase-gallery.service'

@Component({
  selector: 'app-showcase-gallery-photos',
  templateUrl: './showcase-gallery-photos.component.html',
  styleUrls: ['./showcase-gallery-photos.component.sass']
})
export class ShowcaseGalleryPhotosComponent implements OnInit {
	constructor(
		private galleryService: ShowcaseGalleryService
	) { }

  ngOnInit() {
  }

}
