import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ) {
    let group: any = {};

    questions.forEach(question => {
			if ('checkbox' === question.controlType) {
				let values = question.hasOwnProperty('value') && question.value ? JSON.parse(question.value) : [];

				question['options'].forEach(_ => {
					let _fc =  new FormControl(!!(values.indexOf(_.key) >= 0) || '');
					group[question.key +'__'+ _.key] = _fc;
				});

				// if (question.hasOwnProperty('value') && question.value) {
					// let values = JSON.parse(value);

				// }
			}

      group[question.key] = question.required ? new FormControl({value: question.value || '', disabled: question['disabled']}, Validators.required)
                                              : new FormControl({value: question.value || '', disabled: question['disabled']});
    });
    return new FormGroup(group);
  }
}
