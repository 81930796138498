import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-festivities-f2010',
  templateUrl: './showcase-festivities-f2010.component.html',
  styleUrls: ['./showcase-festivities-f2010.component.sass']
})
export class ShowcaseFestivitiesF2010Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
