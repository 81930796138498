import { Component, OnInit } from '@angular/core';
import { ShowcaseGalleryService } from '../showcase-gallery.service'
import { Routes, Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-showcase-gallery-videos',
  templateUrl: './showcase-gallery-videos.component.html',
  styleUrls: ['./showcase-gallery-videos.component.sass']
})
export class ShowcaseGalleryVideosComponent implements OnInit {

  constructor(
		private galleryService: ShowcaseGalleryService,
		private route: ActivatedRoute
	) { }

  ngOnInit() {
  }

}
