import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../users/user.service';
import { ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { QuestionControlService } from '../dynamic-form/question-control.service';
import { TextboxQuestion } from '../dynamic-form/question-textbox';

@Component({
  selector: 'edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.sass'],
	providers: [QuestionControlService]
})
export class EditPasswordDialogComponent implements OnInit {
	@ViewChild('dynForm', {static: false}) dynForm: DynamicFormComponent;
  form:any = {};
  formProcessing;
  errorMsg;
	questions = [
		new TextboxQuestion({ key: 'oldPassword', type: 'password', label: 'Ancien mot de passe', required: true }),
		new TextboxQuestion({ key: 'newPassword', type: 'password', label: 'New password', required: true }),
		new TextboxQuestion({ key: 'newPasswordVerif', type: 'password', label: 'Repeter le mot de passe', required: true })
	];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EditPasswordDialogComponent>,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

  }

  openSnackBar(message: string, action: string = '') {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  submit(ev): void {
		this.form = ev.value;
    this.errorMsg = null;

    if (!this.form.newPassword || !this.form.newPasswordVerif)
      this.errorMsg = 'Veuillez rensigner tous les champs.';
    else if (this.form.newPassword !== this.form.newPasswordVerif)
      this.errorMsg = 'Les deux mots de passe ne correspondent pas.';

    if (this.errorMsg) {
      this.ref.detectChanges();
      return;
    }

    this.dynForm.submitting = true;
    this.userService.save(this.data.user.id, this.form)
      .subscribe(r => {
        this.dynForm.submitting = false;
        if (!r.status) {
          this.errorMsg = r.message;
          this.ref.detectChanges();
          return;
        }

        this.closeDialog(true);
      })
  }

  closeDialog(status = false): void {
    this.openSnackBar(status ? 'Votre mot de passe a été changé avec succès.' : 'Une érreur est survenue.');
    this.dialogRef.close(status);
  }
}
