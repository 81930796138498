import { Component, OnInit, Input } from '@angular/core';
import { PostService } from '../../posts/post.service';
import { SiteService } from '../../sites/site.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service'
import { ResponseActionService } from '../../@services/response-action.service';

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.sass']
})
export class PostsListComponent implements OnInit {
	@Input() admin;
  @Input() list;
  site;
	user
	checks: any = {};
	checkAllChecked;
	deleting;

  constructor(
    private postService: PostService,
    private siteService: SiteService,
    private route: ActivatedRoute,
		private auth: AuthService,
		private responseAcion: ResponseActionService
  ) { }

  async ngOnInit() {
		await this.auth.check().then(r => this.user = r)
		
		if(this.user.targetDomain !== 'system')
			this.siteService.find(this.user.targetId)
				.subscribe(r => this.site = r)
		
		if (!this.list)
			await this.postService.where([['targetId', this.user.targetId], ['targetDomain', 'blog']])
				.subscribe(r => {this.list = r; console.log('liste>>>>>>>',this.list)})
		
  }

  removePost(post): void {
    this.list.splice(this.list.indexOf(post), 1);
  }
	
	toggleCheckAll(ev) {
		for (let key in this.checks) {
			if (!this.checks.hasOwnProperty(key)) continue;
			
			this.checks[key] = ev.checked;
		}
	}
	
	getChecked() {
		let checks = this.checks, items = [];
		
		for (let key in checks) {
			if (!checks.hasOwnProperty(key)) continue;
			if (!checks[key]) continue;
			
			this.list.forEach(item => {
				if (item.id == key)
					items.push(item);
			});				
		}
		
		return items;
	}
	
	destroy(e = null) {
		if (!confirm("Etes vous sûr de réellement vouloir supprimer?"))
			return null
		
		let items = e ? [e] : this.getChecked();
		console.log(items)
		this.deleting = true;
		items.forEach(item => {
			this.postService.destroy(item.id)
				.subscribe(r => this.responseAcion.handle(r, () => this.deleting = false, () => this.list.splice(this.list.indexOf(item), 1)));
		});
	}

}
