import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-festivities',
  templateUrl: './showcase-festivities.component.html',
  styleUrls: ['./showcase-festivities.component.sass']
})
export class ShowcaseFestivitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
