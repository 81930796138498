import { CommonModule } from '@angular/common';
import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MY_FORMATS } from "../@config/date-format.config";

import { NgMaterialModule } from './ng-material.module';
import { EditPasswordDialogComponent } from '../@widgets/edit-password-dialog/edit-password-dialog.component';
import { DynamicFormComponent } from '../@widgets/dynamic-form/dynamic-form.component';
import { FileFormComponent } from '../@widgets/file-form/file-form.component';
import { DynamicFormQuestionComponent } from '../@widgets/dynamic-form/dynamic-form-question.component';
import { SpeedDialFabComponent } from '../@widgets/speed-dial-fab/speed-dial-fab.component';
import { StatusMessageComponent } from '../@widgets/status-message/status-message.component';
import { MugshotComponent } from '../@widgets/mugshot/mugshot.component';
import { ThumbnailProfileComponent } from '../@widgets/thumbnail-profile/thumbnail-profile.component';
import { LoaderComponent } from '../@widgets/loader/loader.component';
import { DefinitionsListComponent } from '../@widgets/definitions-list/definitions-list.component';
// import { FileInputComponent } from '../@widgets/file-input/file-input.component';
import { CloseButtonComponent } from '../@widgets/close-button/close-button.component';
import { UsersModule } from '../users/users.module';
import { SitesNewComponent } from '../sites/sites-new/sites-new.component';
import { PostThumbComponent } from '../posts/@widgets/post-thumb/post-thumb.component';
import { PostsListComponent } from '../posts/posts-list/posts-list.component';
import { PostsViewComponent } from '../posts/posts-view/posts-view.component';
import { PostsNewComponent } from '../posts/posts-new/posts-new.component';
import { PostsEditComponent } from '../posts/posts-edit/posts-edit.component';
import { UsersListComponent } from '../users/users-list/users-list.component';
import { UsersViewComponent } from '../users/users-view/users-view.component';
import { UsersNewComponent } from '../users/users-new/users-new.component';
import { UsersEditComponent } from '../users/users-edit/users-edit.component';
import { CardComponent } from '../@widgets/card/card.component';
import { DialogComponent } from '../@widgets/dialog/dialog.component';
import { EmptyComponent } from '../@widgets/empty/empty.component';
import { StaffFormComponent } from '../@widgets/staff-form/staff-form.component';
import { AdminSecondaryNavPanelComponent } from '../admin/admin-secondary-nav-panel/admin-secondary-nav-panel.component';
import { ShowcaseHeaderComponent } from '../showcase/showcase-header/showcase-header.component';
import { ShowcaseFooterComponent } from '../showcase/showcase-footer/showcase-footer.component';
import { ShowcaseSecondaryNavComponent } from '../showcase/showcase-secondary-nav/showcase-secondary-nav.component'
import { PostSampleComponent } from '../@widgets/post-sample/post-sample.component';
import { AdSampleComponent } from '../@widgets/ad-sample/ad-sample.component';
import { SelectItemsComponent } from '../@widgets/select-items/select-items.component';
import { ListItemsComponent } from '../@widgets/list-items/list-items.component';
import { AdminMembresViewComponent } from '../admin/admin-membres/admin-membres-view/admin-membres-view.component';
import { PostComponent } from '../@widgets/post/post.component';
// import { PostThumbComponent } from '../@widgets/post-thumb/post-thumb.component';


@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
		RouterModule,
    RichTextEditorModule,
		RichTextEditorAllModule,
		ButtonModule,
		DialogModule,
		HttpClientModule,
  ],
  declarations: [
    SpeedDialFabComponent,
    StatusMessageComponent,
		DropDownListComponent,
    MugshotComponent,
    ThumbnailProfileComponent,
    LoaderComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    CloseButtonComponent,
    DefinitionsListComponent,
		CardComponent,
		DialogComponent,
		EditPasswordDialogComponent,
		EmptyComponent,
    AdminSecondaryNavPanelComponent,
    PostsListComponent,
    PostsViewComponent,
    PostsEditComponent,
    PostsNewComponent,
    UsersListComponent,
    UsersViewComponent,
    UsersEditComponent,
    UsersNewComponent,
    PostThumbComponent,
    FileFormComponent,
		ShowcaseHeaderComponent,
		ShowcaseFooterComponent,
		ShowcaseSecondaryNavComponent,
		PostSampleComponent,
		AdSampleComponent,
		SitesNewComponent,
		StaffFormComponent,
		SelectItemsComponent,
		ListItemsComponent,
		AdminMembresViewComponent,
    // PostThumbComponent,
    PostComponent,
  ],
  exports: [
    CommonModule,
    NgMaterialModule,
		HttpClientModule,
    FormsModule,
    RichTextEditorModule,
		RichTextEditorAllModule,
		ButtonModule,
		DropDownListComponent,
		DialogModule,
		RouterModule,
    ReactiveFormsModule,
    SpeedDialFabComponent,
    LoaderComponent,
    CloseButtonComponent,
    StatusMessageComponent,
    MugshotComponent,
    ThumbnailProfileComponent,
    DefinitionsListComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
		CardComponent,
		DialogComponent,
		EditPasswordDialogComponent,
		EmptyComponent,
    AdminSecondaryNavPanelComponent,
    PostsListComponent,
    PostsViewComponent,
    PostsEditComponent,
    PostsNewComponent,
    UsersListComponent,
    UsersViewComponent,
    UsersEditComponent,
    UsersNewComponent,
    PostThumbComponent,
    FileFormComponent,
		ShowcaseHeaderComponent,
		ShowcaseFooterComponent,
		ShowcaseSecondaryNavComponent,
		PostSampleComponent,
		AdSampleComponent,
		SitesNewComponent,
		StaffFormComponent,
		SelectItemsComponent,
		ListItemsComponent,
		AdminMembresViewComponent,
    // PostThumbComponent,
    PostComponent,
  ],
  entryComponents: [
		EditPasswordDialogComponent, AdminSecondaryNavPanelComponent, DialogComponent, SelectItemsComponent, ListItemsComponent, AdminMembresViewComponent
	],
  providers: [
		ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService,
    {
      provide: NgModuleFactoryLoader,
      useClass: SystemJsNgModuleLoader
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ]
})
export class SharedModule { }
