import $ from 'jquery';

export const apiUrl = 'http://bangou-cameroun.com/api/';
// export const apiUrl = 'http://127.0.0.1:8080/edsa-nodejs/bangou/api/';
export const uploadsPath = apiUrl +'assets/uploads/';
export const themesPath = 'assets/themes/';

export const colorsList = {
  'black': 'Noir',
  'gray': 'Gris',
  'white': 'Blanc',
  'red': 'Rouge',
  'darkred': 'Rouge foncé',
  'blue': 'Bleu',
  'darkblue': 'Bleu foncé',
  'lightblue': 'Bleu clair',
  'green': 'Vert',
  'darkgreen': 'Vert foncé',
  'lightgreen': 'Vert clair',
  'yellow': 'Jaune',
  'orange': 'Orange',
  'purple': 'Violet'
};
export const textSettings = {
  weight: {normal: 'Normal', bold: 'Gras', bolder: 'Tres gras', lighter: 'Leger'},
  style: {normal: 'Normal', 'italic': 'Italique', oblique: 'Oblique'},
  transform: {none: 'Aucune', capitalize: 'Capitales', uppercase: 'Majuscules', lowercase: 'Miniscules'},
  align: {left: 'A gauche', right: 'A droite', center: 'Centré'},
  decorationLine: {none: 'Aucune', underline: 'Sous-ligné', overline: 'Sur-ligné', 'line-through': 'Barré'},
  decorationStyle: {solid: 'Solide', dotted: 'Pointillés', dashed: 'Traits intérrompus', wavy: 'Vague', double: 'Doublé'}
};
export const fontsList = {
  'Abril Fat face': 'AbrilFatface-Regular.ttf',
  'Bitter': 'Bitter-Regular.ttf',
  'Courgette': 'Courgette-Regular.ttf',
  'Great Vibes': 'GreatVibes-Regular.ttf',
  'Indie Flower': 'IndieFlower-Regular.ttf',
  'Inria Serif': 'InriaSerif-Regular.ttf',
  'Josefin Sans': 'JosefinSans-Regular.ttf',
  'Kaushan Script': 'KaushanScript-Regular.ttf',
  'Kulim Park': 'KulimPark-Regular.ttf',
  'Lato': 'Lato-Regular.ttf',
  'Lobster': 'Lobster-Regular.ttf',
  'Ma Shan Zheng': 'MaShanZheng-Regular.ttf',
  'Montserrat': 'Montserrat-Regular.ttf',
  'Noto Sans': 'NotoSans-Regular.ttf',
  'Open Sans': 'OpenSans-Regular.ttf',
  'Oswald': 'Oswald-VariableFont_wght.ttf',
  'Playfair Display': 'PlayfairDisplay-Regular.ttf',
  'Poiret One': 'PoiretOne-Regular.ttf',
  'Poppins': 'Poppins-Regular.ttf',
  'PT Sans': 'PTSans-Regular.ttf',
  'Raleway': 'Raleway-Regular.ttf'
};

export function getPrevUrl(url: string): string {
  let url_ = url.split('/')
  url_.splice(-1, 1)
  return url_.join('/')
}

export function getExcerpt(content: string): string {
  return cutText(content.replace(/<(.|\n)*?>/g, ''), 150)
}

export function fakeReload(router, faintRef, targetRef): void {
	router.navigate(faintRef);
	setTimeout(() => router.navigate(targetRef), 5);
};

export function toOptions (r, value = 'value') {
  let options = [];
  for (let key in r) {
    if (!r.hasOwnProperty(key)) continue;
    options.push({key: key, value: 'key' === value ? key : r[key]});
  }
  return options;
};

export function getAddress (el) {
	var address = [];

	if (el.hasOwnProperty('city') && el.city)
		address.push(el.city);

	if (el.hasOwnProperty('streetName') && el.streetName)
		address.push(el.streetName);

	return address.length > 0 ? address.join(', ') : '';
};

export function getToken () {
  return getCookie('__');
};

export function destroyToken () {
  return deleteCookie('__');
};

export function listprops(o) {
  let k = {};for(var p in o) k[p] = o[p];console.log(k)
};

export function storageGet(key) {
	let value = localStorage.getItem(key);

	if (!value)
		return false;

  try {
		value = JSON.parse(value);
	} catch(e) {
		value = value;
	}

	return value;
};

export function storageSet(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
};

export function watch (cond, interval = 500) {
  let maxTimeout = 3 * 60 * 1000,
    startTime = new Date().getTime();
  return new Promise((resolve, reject) => {
    let check = () => {
        let _cond = cond();

        if (new Date().getTime() - startTime >= maxTimeout) {
          console.log(cond, ' TIMEDOUT');
          return reject('TIMEDOUT');
        }

        if (!_cond)
          setTimeout(check, interval);
        else
          resolve(_cond);
        // console.log('WATCHING ', cond);
      };

    check();
  });
};

export function ispartof (haystack, key, needle) {
  haystack.forEach(r => {
    if (r.hasOwnProperty(key) && r[key] == needle)
      return true;
  })
  return false;
};

export function getobjectof (haystack, key, needle) {
  haystack.forEach(r => {//console.log('getx obj now......', r, key, needle, r[key], r[key] == needle)
    if (r.hasOwnProperty(key) && r[key] == needle) {
      //console.log('got some obj......', r)
      return r;
    }
  })
  return null;
};

export function setToken (v) {
  return setCookie('__', v);
};

export function formatDate (d, type = 'full') {
		let date = new Date(d);
		let weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
			months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
			newDate = '';

		switch(type) {
			case 'full':
				newDate = [date.getDate(), months[date.getMonth()], date.getFullYear()].join(' ');
				break;
		}

		return newDate;
	};

export function isMobile () {
  return window.innerWidth <= 900;
};

export function formData (form) {
	for(let key in form) {
		if (form.hasOwnProperty(key) && form[key] && key.toLowerCase().indexOf('date') >= 0)
			form[key] = dateFromMat(form[key])
	}

  let isImage = (obj: any, k: any = null) => {
    if (!obj) return false;
    return 'string' === typeof obj.name && 'number' === typeof obj.size && 'string' === typeof obj.type;
  };
  let formData = new FormData();

  formData.append('apiToken', getToken());

  for (var key in form) {
    if (!form.hasOwnProperty(key) || !form[key]) continue;

    var theKey = form[key];
    var keyType = null;
    if (Array.isArray(theKey)) { // array
      if (isImage(theKey[0], key)) {
        keyType = 'filesArray';
        for (var j = 0; j < theKey.length; j++) {
          formData.append(key +'[]', theKey[j], theKey[j].name);
        }
      }
      else if (typeof theKey[0] === 'object') {
        keyType = 'objectsArray';
        for (var j = 0; j < theKey.length; j++) {
          formData.append(key +'[]', JSON.stringify(theKey[j]));
        }
      }
      else {
        keyType = 'stringsArray';
        for (var j = 0; j < theKey.length; j++) {
          formData.append(key +'[]', theKey[j]);
        }
      }
    }
    else if (typeof theKey === 'object') { // object
      if (isImage(theKey)){
        keyType = 'file';
        formData.append(key, theKey, theKey.name);
      }
      else {
        keyType = 'object';
        formData.append(key, JSON.stringify(theKey));
      }
    }
    else { // common string values
      keyType = 'string';
      formData.append(key, form[key]);
    }
    // console.log(theKey, keyType, typeof theKey.name);
  }

  return formData;
};

export function setCookie (cname, cvalue, exdays = null) {
	exdays = !exdays ? 30 : exdays;
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export function getCookie (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

export function deleteCookie(cname) {
	setCookie(cname, '', -1);
};

export function getDateFromMat(date) {
	return typeof date === 'string' ? date : [date._i.year, String(date._i.month + 1).padStart(2, '0'), String(date._i.date).padStart(2, '0')].join('-');
};

export function dateFromMat(date) {
	return typeof date === 'string' ? date : [date._i.year, String(date._i.month + 1).padStart(2, '0'), String(date._i.date).padStart(2, '0')].join('-');
};

export function excerpt (text, limit = 150) {
	return cutText(text.replace(/<[^>]*>?/gm, ''), limit);
}

export function cutText (text, limit) { return text.length > limit ? text.substring(0, limit - 1) + '...' : text; };

export function getThumb (o) { return uploadsPath +'thumb__'+ o; };

export function getMediaSrc (o, upload = true, thumb = false) { return (!upload ? 'assets/medias/' : uploadsPath + (!thumb ? '' : 'thumb__')) + o; };

export function __ (str) {
		return str;
	};

export function zoomImage(img) {
	let container = $('<div class="_modal-container"></div>'),
		content = $('<div class="_modal-content"></div>'),
		close = $('<div class="close-button">&times;</div>'),
		image = $('<img src="'+ getMediaSrc(img) +'" />');

	content.append(image);
	container.append(content);
	$('body').append(container);

	// dimensions
	let margin = 30,
		availHeight = window.innerHeight - (margin * 2),
		availWidth = window.innerWidth - (margin * 2),
		height, width;

	container.css({position: 'fixed', zIndex: '1022', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.3)'});
	content.css({position: 'relative', borderRadius: '5px', overflow: 'hidden', margin: 'auto'});
	image.css({borderRadius: '5px', width: 'auto !important', minWidth: 'initial !important', maxWidth: 'initial !important'});

	image[0].addEventListener('load', () => {
		let target = image[0];

		let height_ = target.offsetHeight;
		let width_ = target.offsetWidth;
		// if (height_ > width_) {
			height = availHeight > height_ ? height_ : availHeight;
			width = height_ === availHeight ? (width_ * availHeight / height_) : width_;
		// }
		// else {
			// width = availWidth > width_ ? width_ : availWidth;
			// height = width_ === availWidth ? (height_ * availWidth / width_) : height_;
		// }

		image.css({height: height +'px', width: 'auto'});
		content.css({top: margin +'px', left: ((availWidth - image[0].offsetWidth) / 2) +'px'});
	});

	container.click(() => container.remove());
}

export function uriEncode(items) {
  let _items = [];
  for(let e in items) {
    if (!items.hasOwnProperty(e)) continue;

    _items.push(`${e}=${items[e]}`);
  }
  return '?'+ _items.join('&');
}

export function formatCurrency(amount) {
	var friendly = '';
	var amount_ = amount + '';

	do {
		if(amount_.length <= 3) {
			friendly = amount_ + friendly;
			amount_ = '';
		}
		else {
			friendly = '.' + amount_.substring(amount_.length - 3) + friendly;
			amount_ = amount_.substring(0, amount_.length - 3);
		}
	} while(amount_.length > 0)

	return friendly;
}

export function randomString (length){
		var words = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
		var chars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

		function pickOneChar(e = false){
			return arguments[0] ? words[Math.floor(Math.random() * chars.length)] : chars[Math.floor(Math.random() * chars.length)];
		}

		function pickChars(){
			var str = pickOneChar(true);
			for(var i=0; i < length - 1; i++) {
				str += pickOneChar();
			}

			return str;
		}

		return pickChars();
		/*
		var string = pickChars();

		if(!arguments[1])
			return string;

		var params = arguments[1];

		return new Promise(function(resolve, reject) {
			var {collection, field} = params;
			var exists = true, filter = {};
			filter[field] = string;

			async function check() {
				exists = await collection.exists(filter);

				if (!exists) return resolve(string);

				string = pickChars();
				filter[field] = string;

				check();
			}

			check();
		}); */
	};

export function isImage (src) {
	return /(.jpg|.jpeg|.png|.gif|.bmp)$/.test(src.toLowerCase());
};

export function toSlug(str: string): string {
  return str.replace(/[éèëê]/g, 'e').replace(/[àáãå]/g, 'a').replace(/[ùúûüµ]/g, 'u').replace(/[îïìí]/g, 'i').replace(/[ôöòóõø]/g, 'o').replace(/ç/g, 'c').replace(/ñ/g, 'n').replace(/ý/g, 'y').replace(/æ/g, 'ae').replace(/ /g, '-').replace(/[^\w-]/g, '');
};

export function displayNames(list, dialog, component) {
  if (list.length < 1)
    return

  const items = list.map((item: any) => {
    return item.firstName +'  '+ item.lastName
  })

  dialog.open(component, {
    width: '500px',
    maxHeight: (window.innerHeight - 100) +'px',
    data: {list: items}
  })
}
