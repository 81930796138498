import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiUrl } from '../../environments/util';
import { ModelService } from '../@services/model.service';
import { DropdownQuestion } from '../@widgets/dynamic-form/question-dropdown';
import { QuestionBase } from '../@widgets/dynamic-form/question-base';
import { TextboxQuestion } from '../@widgets/dynamic-form/question-textbox';
import { AutocompleteQuestion } from '../@widgets/dynamic-form/question-autocomplete';
import { CheckboxQuestion } from '../@widgets/dynamic-form/question-checkbox';
import { RadioQuestion } from '../@widgets/dynamic-form/question-radio';
import { Router, ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class StaffService extends ModelService {

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
    this.domain = 'staffs'
  }
}
