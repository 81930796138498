import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass']
})
export class CardComponent implements OnInit {
	@Input() title;
	@Input() subTitle;
	@Input() buttons;
	@Input() text;
	@Input() img;
	
  constructor() { }

  ngOnInit() {
  }

}
