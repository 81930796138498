import { Component, OnInit, AfterViewInit } from '@angular/core';
import $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { watch } from '../../../environments/util'

@Component({
  selector: 'app-showcase-home',
  templateUrl: './showcase-home.component.html',
  styleUrls: ['./showcase-home.component.sass']
})
export class ShowcaseHomeComponent implements OnInit {
	sliderItems = [
	{ src: 'banner.jpg', caption: "" },
	{ src: 'gallery/photos/comices/comices5.jpg', caption: "" },
	{ src: 'gallery/photos/danse/danse2.jpg', caption: "" }
	]
	
	profiles: any = [
		{
			name: "WAFFO KOUAMO",
			position: "Chef du village Baloumgou",
			picture: "chef_baloungou.jpg"
		},
		{
			name: "KOUAMO Jean",
			position: "Chef du village Shella",
			picture: "chef_shella.jpg"
		},
		{
			name: "SANONG Jules",
			position: "Chef du village Bélé Feutchi",
			picture: "chef_feutchi.jpg"
		},
		{
			name: "TCHIENGUE Samuel",
			position: "Chef du village Batop",
			picture: "chef_batop.jpg"
		},
		{
			name: "NOUPOYOU Luc",
			position: "Chef du village Djeumkong",
			picture: "chef_djeumkong.jpg"
		},
	]
  constructor(
		private router: Router
  ) { }
	
	ngAfterViewInit() {
		$('.slider').each(function (index, slider) {
			$('.banner-slider, .slider, .slider-item').css('height', window.innerHeight +'px');
			
      watch(() => {return $(slider).length > 0}).then(() => {
        let $slider = $(slider),
          $items = $slider.find('.slider-item'),
          interval = 7000,
          $active = $items.first(),
          switchs = 0,
					maxHeight = 0;

        $items.hide();
        $active.fadeIn();

        let switchAction = () => {
          let $next = switchs + 1 >= $items.length ? $items.first() : $active.next();
					
          $next.fadeIn('slow');
          $active.fadeOut('slow', function() {
            $active = $next;
          });
          switchs = switchs + 1 >= $items.length ? 0 : switchs + 1;
          setTimeout(switchAction, interval);
        };

        if ($items.length > 0)
          setTimeout(switchAction, interval);
      })
    });
		
		$('.jumbotron')[0].style.height = window.innerHeight +'px'
		
		let $scrollBtn = $('#scroll-more-btn'),
			firstMove = () => {
				$scrollBtn.animate({top: '30px'}, 'slow', secondMove)
			},
			secondMove = () => {
				$scrollBtn.animate({top: '0px'}, 'slow', firstMove)
			}
		
		firstMove()
	}

  ngOnInit() {

  }
	
	scrollToMore() {
		$("html, body").animate({ scrollTop: (window.innerHeight - $('#primaryHeader')[0].offsetHeight) }, 1300)
	}
}
